import RestApi from "@/api/RestApi";

export default class Dashboard extends RestApi {
	constructor() {
		super();
		this.baseEndpoint = this.domain + "dashboard/";
	}

	async getHRSuppliers(pagination) {
		let url = this.baseEndpoint + 'hr-suppliers';
		let response = await this.getData(url);
		return response;
	}

}
