import moment from "moment";
export default {
	methods: {
		/**
		 * @param {date} date {string} format
		 * Format Date
		 */
		formatDate(date, format = "DD.MM.YYYY") {
			if (!date) return date;
			return this.$moment(date).format(format);
		},

		/**
		 * @param {date} date
		 * Humanize Date
		 */
		humanizeDate(date) {
			if (!date) return date;
			let dateDiff = this.$moment().diff(date, "seconds");
			return moment.duration(-dateDiff, "seconds").humanize(true);
		}
	}
};
