import View from "@/views/User.vue";
import Index from "@/components/users/List.vue";
import Create from "@/components/users/Create.vue";
import Edit from "@/components/users/Edit.vue";
import Show from "@/components/users/Show.vue";
import MyAccount from "@/components/users/MyAccount.vue";
import store from "@/store";

export default [
	{
		path: "/user",
		name: "user",
		component: View,
		meta: {
			icon: "mdi-account-circle"
		},
		title: "User",
		redirect: { name: "list-user" },
		children: [
			{
				path: "list",
				name: "list-user",
				meta: {
					icon: "mdi-account-circle"
				},
				beforeEnter: (to, from, next) => {
					store.commit("options/setOptionsData", { dialog: false });
					next();
				},
				components: {
					page: Index
				}
			},
			{
				path: "create",
				name: "create-user",
				meta: {
					storeAction: "users/insertItem",
					namespace: "user",
					store: "users",
					title: "Create User",
					icon: "mdi-account-circle"
				},
				beforeEnter: (to, from, next) => {
					store.commit("options/setOptionsData", { dialog: true });
					next();
				},
				components: {
					modal: Create
				}
			},
			{
				path: "edit/:id",
				name: "edit-user",
				meta: {
					storeAction: "users/updateItem",
					namespace: "user",
					store: "users",
					title: "Edit user",
					icon: "mdi-account-circle"
				},
				beforeEnter: (to, from, next) => {
					store.commit("options/setOptionsData", { dialog: true });
					next();
				},
				components: {
					modal: Edit
				}
			},
			{
				path: "show/:id",
				name: "show-user",
				components: {
					page: Show
				}
			},
			{
				path: "my-account",
				name: "my-account",
				meta: {
					storeAction: "users/updateItem",
					namespace: "user",
					store: "users",
					title: "Update Profile",
					icon: "mdi-account-circle"
				},
				components: {
					page: MyAccount
				}
			}
		]
	}
];
