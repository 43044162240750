<template>
  <form-wizard
    :form-data="formData"
    :item="item"
    :namespace="namespace"
    :title="title"
    :icon="icon"
    :store="store"
    :store-action="storeAction"
  ></form-wizard>
</template>
<script>
import { mapGetters } from "vuex";
import Vue from "vue";
export default {
  name: "WorkforceRequestCreate",
  components: {
    FormWizard: () => import("@/components/core/form/FormWizard")
  },
  data() {
    return {
      item: {
        job_positions: [],
        file_url: null,
        preview_url: null,
        description:
          "Describe job position(s): \nWrite fix hourly rate: \nWrite other bonuses:\nWrite basic requirements:"
      }
    };
  },
  created() {},
  async mounted() {
    await this.$store.dispatch("companies/getSuppliers", {
      name: "suppliers",
      company_group: "bmw-suppliers"
    });
    await this.$store.dispatch("companies/getSuppliers", {
      name: "hrSuppliers",
      company_group: "hr-suppliers"
    });
    await this.$store.dispatch("workforceTypes/getItems", { all: true });
    await this.$store.dispatch("jobPositions/getItems", { all: true });
    Vue.set(this.item, "job_positions", this.jobValues);
    let _this = this;
    Event.$on("add_multi_item", function() {
      _this.item.job_positions.push(Vue.util.extend({}, _this.job));
    });

    Event.$on("remove_multi_item", function(index) {
      Vue.delete(_this.item.job_positions, index);
    });
  },
  computed: {
    ...mapGetters({
      suppliers: "companies/suppliers",
      hrSuppliers: "companies/hrSuppliers",
      workforceTypes: "workforceTypes/items",
      jobPositions: "jobPositions/items"
    }),
    job() {
      const json = {
        id: "",
        employees_number: "",
        address: ""
      };
      return json;
    },
    jobValues() {
      const link = [this.job];
      return link;
    },
    icon() {
      return this.$route.meta.icon || "";
    },
    storeAction() {
      return this.$route.meta.storeAction || "";
    },
    store() {
      return this.$route.meta.store || "";
    },
    title() {
      return this.$route.meta.title || "";
    },
    namespace() {
      return this.$route.meta.namespace || "";
    },
    formData() {
      let form = [
        {
          text: "Name*",
          value: "name",
          flex: "col-sm-12 col-md-12",
          required: "required",
          class: "",
          validate: "required"
        },
        {
          text: "Upload file",
          value: "file_url",
          flex: "col-sm-12 col-md-12",
          required: "",
          fileUpload: true,
          class: "",
          validate: ""
        },
        {
          text: "Description*",
          value: "description",
          flex: "col-sm-12 col-md-12",
          required: "required",
          textarea: true,
          class: "",
          validate: "required"
        },
        {
          text: "Company*",
          value: "company_id",
          options: this.suppliers,
          flex: "col-sm-12 col-md-6",
          required: "required",
          class: "",
          validate: "required"
        },
        {
          text: "Workforce type*",
          value: "workforce_request_type_id",
          options: this.workforceTypes,
          flex: "col-sm-12 col-md-6",
          displayName: true,
          required: "required",
          class: "",
          validate: "required"
        },
        {
          text: "Assign request to a company*",
          value: "companies",
          options: this.hrSuppliers,
          multiple: true,
          chips: true,
          flex: "col-sm-12 col-md-12",
          required: "required",
          class: "",
          validate: "required"
        },
        {
          text: "Job position*",
          value: "job_positions",
          multipleAdd: true,
          flex: "col-sm-12 col-md-12",
          required: "required",
          text2: "Number of employees",
          text3: "Address",
          multipleAddOptions: this.jobPositions,
          values: this.item.job_positions,
          class: "",
          validate: "required"
        }
      ];
      return form;
    }
  }
};
</script>
