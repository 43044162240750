import AccessLogsApi from "@/api/accessLogs";
import ErrorHandling from "@/plugins/ErrorHandling";
const errorHandling = new ErrorHandling();
const restApi = new AccessLogsApi();

const getItems = async ({ commit, state }, all = false) => {
	try {
		commit("setLoading", true);
		const options = JSON.parse(JSON.stringify(state.pagination));
		Object.keys(options).forEach(function() {
			options["perPage"] = options["rowsPerPage"];
			options["orderDir"] = options["sortDesc"] ? "desc" : "asc";
		});
		const items = await restApi.getList(all ? all : options);
		commit("setItemsData", items);
		commit("setLoading", false);
	} catch (error) {
		errorHandling.onError(error);
	}
};

const setPagination = async ({ commit }, payload) => {
	commit("setPagination", payload);
};

const showItem = async ({ commit }, itemId) => {
	try {
		commit("setLoading", true);
		const item = await restApi.showItem(itemId);
		commit("setItemData", item);
		commit("setLoading", false);
	} catch (error) {
		errorHandling.onError(error);
	}
};

export default {
	getItems,
	showItem,
	setPagination
};
