import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import moment from "moment";

const state = {
	items: [],
	total: 0,
	loading: false,
	pagination: {
		page: 1,
		rowsPerPage: 12,
		loadRows: 12
	}
};

export default {
	namespaced: true,
	state,
	actions,
	getters,
	mutations
};
