const items = state => state.items;
const hrSuppliers = state => state.hrSuppliers;
const kpis = state => state.kpis;
const suppliers = state => state.suppliers;
const total = state => state.total;
const item = state => state.item;
const loading = state => state.loading;
const pagination = state => state.pagination;
const activityLog = state => state.activityLog;

export default {
	items,
	total,
	kpis,
	hrSuppliers,
	suppliers,
	item,
	loading,
	activityLog,
	pagination
};
