<template>
  <candidate-simple-list
    :names="names"
    :icon="icon"
    :title="title"
    :route-name="routeName"
    :show="show"
    :go-to="goTo"
    :show-data-only="showDataOnly"
    :show-comments-button="showCommentsButton"
    :show-activity-button="showActivityButton"
    :key-title="keyTitle"
    :namespace="namespace"
  ></candidate-simple-list>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "CandidateList",
  components: {
    CandidateSimpleList: () => import("@/components/core/lists/CandidateList")
  },
  props: {
    showDataOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      routeName: "candidate",
      namespace: "candidates",
      title: "Candidates",
      showCommentsButton: true,
      showActivityButton: true,
      keyTitle: "name",
      goTo: "/candidate",
      show: true,
      names: [
        {
          text: "Name",
          value: "name",
          sortable: true
        },
        {
          text: "HR Supplier ",
          value: "company.name",
          sortable: false
        },
        {
          text: "Active candidates",
          value: "active_suggestions_as_candidate_count",
          sortable: false
        }
      ]
    };
  },
  computed: {
    icon() {
      return this.$route.meta.icon || "";
    }
  },
  created() {}
};
</script>
