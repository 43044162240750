<template>
  <v-container fluid>
    <v-row justify="space-around" no-gutters>
      <v-col cols="12">
        <v-sheet color="grey lighten-5" class="pa-12" v-if="item">
          <v-row align="start" justify="start">
            <v-col :cols="isSupplierCompany ? 4 : 8" class=" text-left">
              <h1
                class="text-uppercase  grey--text text--darken-2 mb-2 display-1"
              >
                <v-avatar size="100" class="pr-5">
                  <v-img contain alt="company" :src="item.logo_preview" />
                </v-avatar>
                <span>{{ item.name }}</span>
              </h1>
              <div
                class="mb-2 headline font-weight-light grey--text text--darken-2"
                v-if="item.city"
              >
                {{ item.city.name }}
              </div>
              <div
                class="mb-4 subtitle-1 font-weight-light grey--text text--darken-2"
                v-if="item.company_type"
              >
                {{ item.company_type.name }}
              </div>
            </v-col>
            <v-col
              cols="4"
              class=" text-left"
              align-self="end"
              v-if="isSupplierCompany"
            >
              <div
                class="mb-4 subtitle-1 font-weight-light grey--text text--darken-2"
              >
                <v-autocomplete
                  v-model="item.company_status_id"
                  :items="companyStatus"
                  item-text="name"
                  outlined
                  item-value="id"
                  @change="updateCompanyStatus()"
                  label="Company Status"
                  placeholder="Company Status"
                  chips
                ></v-autocomplete>
              </div>
            </v-col>
            <v-col class="font-italic pa-6">{{ item.description }} </v-col>
          </v-row>
        </v-sheet>
      </v-col>
      <v-divider width="100%"></v-divider>
      <v-col cols="12">
        <v-tabs
          v-model="tab"
          :background-color="color"
          fixed-tabs
          :active-class="'grey lighten-5 ' + textColor"
          dark
          icons-and-text
        >
          <v-tabs-slider color="transparent"></v-tabs-slider>

          <v-tab href="#tab-1">
            KPIs
            <v-icon>mdi-account-group</v-icon>
          </v-tab>

          <v-tab href="#tab-2" v-if="userCan('list-workforce')">
            Workforce request
            <v-icon>mdi-lan</v-icon>
          </v-tab>

          <v-tab href="#tab-3" v-if="userCan('list-employee')">
            Employees
            <v-icon>mdi-account-hard-hat</v-icon>
          </v-tab>

          <v-tab href="#tab-4" v-if="userCan('list-file')">
            Files
            <v-icon>mdi-file-multiple</v-icon>
          </v-tab>

          <v-tab href="#tab-5" v-if="userCan('assign-company')">
            Related companies
            <v-icon>mdi-office-building</v-icon>
          </v-tab>
          <v-tab href="#tab-6" v-if="userCan('read-comments-company')">
            Comments
            <v-badge
              :content="item.comments_count"
              :value="item.comments_count"
              overlap
            >
              <v-icon>mdi-comment-text-multiple</v-icon>
            </v-badge>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item :value="'tab-1'" active-class="grey lighten-5">
            <kpi-list v-if="item.kpis" :kpis="item.kpis"></kpi-list>
          </v-tab-item>
          <v-tab-item
            :value="'tab-2'"
            active-class="grey lighten-5"
            v-if="userCan('list-workforce')"
          >
            <workforce-list
              v-if="item.company_workforce_requests"
              :show-data-only="true"
            ></workforce-list>
          </v-tab-item>
          <v-tab-item
            :value="'tab-3'"
            active-class="grey lighten-5"
            v-if="userCan('list-employee')"
          >
            <employees-list
              v-if="item.company_employees"
              :show-data-only="true"
            ></employees-list>
          </v-tab-item>
          <v-tab-item
            :value="'tab-4'"
            active-class="grey lighten-5"
            v-if="userCan('list-file')"
          >
            <file-list
              v-if="item.files"
              :show-data-only="true"
              :passed-data="item.files"
            ></file-list>
          </v-tab-item>
          <v-tab-item
            :value="'tab-5'"
            active-class="grey lighten-5"
            v-if="userCan('assign-company')"
          >
            <assign-company
              v-if="item.id"
              :company-id.sync="item.id"
              :related-companies.sync="item.related_companies"
              :go-to-url="goTo"
            ></assign-company>
          </v-tab-item>
          <v-tab-item :value="'tab-6'" active-class="grey lighten-5">
            <comment-list type="companies" :typeId="id"></comment-list>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import permission from "@/mixins/permission";
import date from "@/mixins/date";
export default {
  Name: "companyShow",
  components: {
    KpiList: () => import("@/components/core/cards/DashboardKpi"),
    FileList: () => import("@/components/files/List"),
    AssignCompany: () => import("@/components/companies/tabs/AssignCompany"),
    WorkforceList: () => import("@/components/workforceRequests/List"),
    EmployeesList: () => import("@/components/employees/List"),
    CommentList: () => import("@/components/comments/List")
  },
  mixins: [permission, date],
  data() {
    return {
      item: {},
      tab: null
    };
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch("workforce/setPagination", { company_id: null });
    this.$store.dispatch("employees/setPagination", { company_id: null });
    this.$store.dispatch("files/setPagination", { company_id: null });
    next();
  },
  async mounted() {
    const _this = this;
    this.$store.dispatch("workforce/setPagination", { company_id: _this.id });
    this.$store.dispatch("employees/setPagination", { company_id: _this.id });
    this.$store.dispatch("files/setPagination", { company_id: _this.id });
    await this.$store.dispatch("companyStatus/getItems", { all: true });
    _this.$store.dispatch(_this.store + "/showItem", _this.id).then(() => {
      _this.item = _this.$store.getters[_this.store + "/item"];
    });
  },
  computed: {
    ...mapGetters({
      companyStatus: "companyStatus/items"
    }),
    goTo() {
      return "/company/show/" + this.id;
    },
    isSupplierCompany() {
      return this.item.company_type.group == "bmw-suppliers";
    },
    icon() {
      return this.$route.meta.icon || "";
    },
    id() {
      return this.$route.params.id || null;
    },
    textColor() {
      return this.$route.meta.textColor || "";
    },
    color() {
      return this.$route.meta.color || "";
    },
    store() {
      return this.$route.meta.store || "";
    },
    title() {
      return this.$route.meta.title || "";
    },
    namespace() {
      return this.$route.meta.namespace || "";
    }
  },
  methods: {
    updateCompanyStatus() {
      this.$store.dispatch("companies/updateItem", this.item).then(() => {});
    }
  }
};
</script>
