<template>
  <form-wizard
    :form-data="formData"
    :item="item"
    :namespace="namespace"
    :title="title"
    :icon="icon"
    :store="store"
    :store-action="storeAction"
  ></form-wizard>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "productivityGenerate",
  components: {
    FormWizard: () => import("@/components/core/form/FormWizard")
  },
  data() {
    return {
      item: {}
    };
  },
  created() {},
  async mounted() {
    await this.$store.dispatch("companies/getSuppliers", {
      name: "hrSuppliers",
      company_group: "hr-suppliers"
    });
  },
  computed: {
    ...mapGetters({
      hrSuppliers: "companies/hrSuppliers"
    }),
    icon() {
      return this.$route.meta.icon || "";
    },
    storeAction() {
      return this.$route.meta.storeAction || "";
    },
    store() {
      return this.$route.meta.store || "";
    },
    title() {
      return this.$route.meta.title || "";
    },
    namespace() {
      return this.$route.meta.namespace || "";
    },
    formData() {
      let form = [
        {
          text: "Select HR supplier*",
          value: "companies",
          flex: "col-sm-12 col-md-12",
          required: "required",
          chips: true,
          multiple: true,
          options: this.hrSuppliers,
          class: "",
          validate: "required"
        },
        {
          text: "Select month*",
          value: "date",
          flex: "col-sm-12 col-md-12",
          required: "required",
          fullDate: true,
          month: true,
          class: "",
          validate: "required"
        },
        {
          text: "Email*",
          value: "email",
          flex: "col-sm-12 col-md-12",
          required: "required",
          class: "",
          validate: "required"
        }
      ];
      return form;
    }
  }
};
</script>
