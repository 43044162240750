import CommentsApi from "@/api/comments";
import ErrorHandling from "@/plugins/ErrorHandling";

const errorHandling = new ErrorHandling();
const restApi = new CommentsApi();

const getItems = async ({commit, state}, all) => {
    try {
        commit("setLoading", true);
        const options = JSON.parse(JSON.stringify(state.pagination));
        Object.keys(options).forEach(function () {
            options["perPage"] = options["rowsPerPage"];
            options["orderDir"] = options["sortDesc"] ? "desc" : "asc";
        });
        const items = await restApi.getList(all ? all : options);
        commit("setItemsData", items);
        commit("setLoading", false);
    } catch (error) {
        errorHandling.onError(error);
    }
};

const setPagination = async ({commit}, payload) => {
    commit("setPagination", payload);
};

const getItem = async ({commit}, itemId) => {
    try {
        commit("resetItemData");
        commit("setLoading", true);
        const item = await restApi.getItem(itemId);
        commit("setItemData", item);
        commit("setLoading", false);
    } catch (error) {
        errorHandling.onError(error);
    }
};

const insertItem = async ({commit}, item) => {
    try {
        commit("setLoading", true);
        const {data} = await restApi.insertItem(item);
        commit("pushItemToList", data);
        commit("setLoading", false);
    } catch (error) {
        errorHandling.onError(error);
    }
};

const updateItem = async ({commit}, item) => {
    try {
        commit("setLoading", true);
        await restApi.updateItem(item.id, item);
        commit("updateList", item);
        commit("setLoading", false);
    } catch (error) {
        errorHandling.onError(error);
    }
};

const deleteItem = async ({commit}, item) => {
    try {
        commit("setLoading", true);
        await restApi.deleteItem(item);
        commit("setLoading", false);
    } catch (error) {
        errorHandling.onError(error);
    }
};
const seenItem = async ({commit}, item) => {
    try {
        commit("setLoading", true);
        await restApi.seenStatus(item);
        commit("setLoading", false);
    } catch (error) {
        errorHandling.onError(error);
    }
}

export default {
    getItems,
    getItem,
    setPagination,
    insertItem,
    updateItem,
    deleteItem,
	seenItem
};
