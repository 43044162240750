import View from "@/views/Productivity.vue";
import Index from "@/components/productivity/List.vue";
import Upload from "@/components/productivity/Upload.vue";
import Generate from "@/components/productivity/Generate.vue";
import Show from "@/components/productivity/Show.vue";
import store from "@/store";

export default [
	{
		path: "/productivity",
		name: "productivity",
		component: View,
		title: "Productivity",
		redirect: {
			name: "list-productivity"
		},
		children: [
			{
				path: "list",
				name: "list-productivity",
				meta: {
					icon: "mdi-finance"
				},
				beforeEnter: (to, from, next) => {
					store.commit("options/setOptionsData", {
						dialog: false
					});
					next();
				},
				components: {
					page: Index
				}
			},
			{
				path: "upload",
				name: "upload-productivity",
				meta: {
					storeAction: "productivity/uploadProductivity",
					namespace: "productivity",
					store: "productivity",
					title: "Upload productivity",
					icon: "mdi-finance"
				},
				beforeEnter: (to, from, next) => {
					store.commit("options/setOptionsData", {
						dialog: true
					});
					next();
				},
				components: {
					modal: Upload
				}
			},
			{
				path: "generate",
				name: "generate-productivity",
				meta: {
					storeAction: "productivity/generateProductivity",
					namespace: "productivity",
					store: "productivity",
					title: "Productivity Report",
					icon: "mdi-finance"
				},
				beforeEnter: (to, from, next) => {
					store.commit("options/setOptionsData", {
						dialog: true
					});
					next();
				},
				components: {
					modal: Generate
				}
			},
			{
				path: "show/:id",
				meta: {
					namespace: "productivity",
					title: "Productivity",
					store: "productivity",
					icon: "mdi-finance"
				},
				beforeEnter: (to, from, next) => {
					store.commit("options/setOptionsData", {
						dialog: false
					});
					next();
				},
				name: "show-productivity",
				components: {
					page: Show
				}
			}
		]
	}
];
