<template>
  <form-wizard
    :form-data.sync="formData"
    :item="item"
    :namespace="namespace"
    :title="title"
    :displayName="true"
    :icon="icon"
    :store="store"
    :store-action="storeAction"
  ></form-wizard>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "FileCreate",
  components: {
    FormWizard: () => import("@/components/core/form/FormWizard")
  },
  data() {
    return {
      item: {
        url: null,
        preview_url: null,
        model: "Company"
      }
    };
  },
  created() {},
  async mounted() {
    await this.$store.dispatch("companies/getItems", { all: true });
    await this.$store.dispatch("candidates/getItems", { all: true });
    await this.$store.dispatch("fileTypes/getItems", { all: true });
  },
  computed: {
    ...mapGetters({
      fileTypes: "fileTypes/items",
      companies: "companies/items",
      employees: "candidates/items"
    }),
    icon() {
      return this.$route.meta.icon || "";
    },
    storeAction() {
      return this.$route.meta.storeAction || "";
    },
    store() {
      return this.$route.meta.store || "";
    },
    title() {
      return this.$route.meta.title || "";
    },
    namespace() {
      return this.$route.meta.namespace || "";
    },
    formData() {
      let form = [
        {
          text: "File*",
          value: "url",
          flex: "col-sm-12 col-md-12",
          required: "required",
          fileUpload: true,
          class: "",
          validate: ""
        },
        {
          text: "File Name*",
          value: "name",
          flex: "col-sm-12 col-md-6",
          required: "required",
          class: "",
          validate: "required"
        },
        {
          text: "File type*",
          value: "file_type_id",
          flex: "col-sm-12 col-md-6",
          required: "required",
          chips: true,
          multiple: false,
          displayName: true,
          options: this.fileTypes,
          class: "",
          validate: "required"
        },
        {
          text: "Assign file to *",
          value: "model",
          flex: "col-sm-12 col-md-6",
          required: "required",
          chips: true,
          multiple: false,
          readonly: true,
          options: [
            { id: "Company", name: "Company" },
            { id: "Employee", name: "Employee" }
          ],
          class: "",
          validate: "required"
        },
        {
          text: "Select*",
          value: "model_id",
          flex: "col-sm-12 col-md-6",
          required: "required",
          chips: true,
          multiple: false,
          options:
            this.item.model === "Company" ? this.companies : this.employees,
          class: "",
          validate: "required"
        }
      ];
      return form;
    }
  }
};
</script>
