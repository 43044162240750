import View from "@/views/Candidate.vue";
import Index from "@/components/candidates/List.vue";
import Create from "@/components/candidates/Create.vue";
import Edit from "@/components/candidates/Edit.vue";
import Show from "@/components/candidates/Show.vue";
import store from "@/store";

export default [
	{
		path: "/candidate",
		name: "candidate",
		component: View,
		title: "Candidate",
		meta: {
			icon: "mdi-account-multiple-plus",
			textColor: "amber--text text--darken-3",
			color: "amber darken-3",
			colorHex:"#ff8f00"
		},
		redirect: { name: "list-candidate" },
		children: [
			{
				path: "list",
				name: "list-candidate",
				meta: {
					icon: "mdi-account-multiple-plus",
					textColor: "amber--text text--darken-3",
					color: "amber darken-3"
				},
				beforeEnter: (to, from, next) => {
					store.commit("options/setOptionsData", { dialog: false });
					next();
				},
				components: {
					page: Index
				}
			},
			{
				path: "create",
				name: "create-candidate",
				meta: {
					storeAction: "candidates/insertItem",
					namespace: "candidate",
					textColor: "amber--text text--darken-3",
					color: "amber darken-3",
					colorHex: "#FF8F00",
					store: "candidates",
					title: "Create Candidate",
					icon: "mdi-account-multiple-plus"
				},
				beforeEnter: (to, from, next) => {
					store.commit("options/setOptionsData", { dialog: true });
					next();
				},
				components: {
					modal: Create
				}
			},
			{
				path: "edit/:id",
				name: "edit-candidate",
				meta: {
					storeAction: "candidates/updateItem",
					namespace: "candidate",
					textColor: "amber--text text--darken-3",
					color: "amber darken-3",
					colorHex: "#FF8F00",
					store: "candidates",
					title: "Edit Candidate",
					icon: "mdi-account-multiple-plus"
				},
				beforeEnter: (to, from, next) => {
					store.commit("options/setOptionsData", { dialog: true });
					next();
				},
				components: {
					modal: Edit
				}
			},
			{
				path: "show/:id",
				meta: {
					namespace: "candidate",
					title: "Candidate",
					store: "candidates",
					textColor: "amber--text text--darken-3",
					color: "amber darken-3",
					colorHex: "#FF8F00",
					icon: "mdi-account-multiple-plus"
				},
				beforeEnter: (to, from, next) => {
					store.commit("options/setOptionsData", {
						dialog: false
					});
					next();
				},
				name: "show-candidate",
				components: {
					page: Show
				}
			}
		]
	}
];
