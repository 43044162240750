import View from "@/views/Report.vue";
import Index from "@/components/reports/List.vue";
import store from "@/store";

export default [
	{
		path: "/report",
		name: "report",
		component: View,
		title: "Reporting",
		redirect: {
			name: "list-report"
		},
		children: [
			{
				path: "list",
				name: "list-report",
				meta: {
					icon: "mdi-file-chart"
				},
				beforeEnter: (to, from, next) => {
					store.commit("options/setOptionsData", {
						dialog: false
					});
					next();
				},
				components: {
					page: Index
				}
			}
		]
	}
];
