import ReportsApi from "@/api/reports";
import ErrorHandling from "@/plugins/ErrorHandling";
const errorHandling = new ErrorHandling();
const restApi = new ReportsApi();

const getItems = async ({ commit, state }) => {
	try {
		commit("setLoading", true);
		const items = await restApi.getList();
		commit("setItemsData", items);
		commit("setLoading", false);
	} catch (error) {
		errorHandling.onError(error);
	}
};

const setPagination = async ({ commit }, payload) => {
	commit("setPagination", payload);
};

const generateReport = async ({ commit }, item) => {
	try {
		commit("setLoading", true);
		await restApi.generateReport(item);
		commit("setLoading", false);
	} catch (error) {
		errorHandling.onError(error);
	}
};

const deleteItem = async ({ commit }, item) => {
	try {
		commit("setLoading", true);
		await restApi.deleteItem(item);
		commit("setLoading", false);
	} catch (error) {
		errorHandling.onError(error);
	}
};

export default {
	getItems,
	setPagination,
	deleteItem,
	generateReport
};
