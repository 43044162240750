<template>
  <company-simple-list
    :names="names"
    :icon="icon"
    :title="title"
    :route-name="routeName"
    :key-title="keyTitle"
    :go-to="goTo"
    :show-assignment-button="showAssignmentButton"
    :show-activity-button="showActivityButton"
    :show-data-only="showDataOnly"
    :show-comments-button="showCommentsButton"
    :show="show"
    :namespace="namespace"
  ></company-simple-list>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "CompanyList",
  components: {
    CompanySimpleList: () => import("@/components/core/lists/CompanyList")
  },
  props: {
    showDataOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    goTo: {
      type: String,
      required: false,
      default: "/company"
    }
  },
  data() {
    return {
      routeName: "company",
      namespace: "companies",
      title: "Companies",
      keyTitle: "name",
      show: true,
      showAssignmentButton: true,
      showActivityButton: true,
      showCommentsButton: true,
      names: [
        {
          text: "Name",
          value: "name",
          sortable: true
        },
        {
          text: "City",
          value: "city.name",
          sortable: false
        },
        {
          text: "Company Type",
          value: "company_type.name",
          sortable: false
        },
        {
          text: "Company Status",
          value: "company_status.name",
          sortable: false
        }
      ]
    };
  },
  computed: {
    icon() {
      return this.$route.meta.icon || "";
    }
  },
  created() {}
};
</script>
