<template>
  <v-container fluid>
    <v-row justify="space-around" no-gutters>
      <v-col cols="12">
        <v-sheet color="grey lighten-5" v-if="user">
          <v-card transparent flat>
            <v-img
              max-height="250px"
              class="white--text"
              :src="require('@/assets/mvs/page-0.jpg')"
            >
            </v-img>
            <v-row>
              <v-col
                class="align-start justify-start layout text-center"
                cols="12"
                sm="6"
                offset-md="1"
                md="2"
              >
                <v-avatar size="140" color="grey lighten-4" class="mt-n14">
                  <v-icon size="150" color="grey lighten-1"
                    >mdi-account-circle</v-icon
                  >
                </v-avatar>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="7"
                class="text-center text-xl-left text-lg-left text-md-left"
              >
                <h2 class="grey--text text--darken-1 ma-2 display-1">
                  {{ user.name }}
                </h2>
              </v-col>
              <v-spacer></v-spacer>
            </v-row>
            <v-row>
              <v-col cols="12">
                <form-wizard
                  :form-data="formData"
                  :item="item"
                  :go-to="goTo"
                  :namespace="namespace"
                  :title="title"
                  :icon="icon"
                  :store="store"
                  :store-action="storeAction"
                ></form-wizard>
              </v-col>
            </v-row>
          </v-card>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "MyAccount",
  data() {
    return {
      activeTab: null,
      password: null
    };
  },
  components: {
    FormWizard: () => import("@/components/core/form/FormWizard")
  },
  computed: {
    goTo() {
      return "/user/my-account";
    },
    item() {
      const { id, name, email } = this.user;
      return { id, name, email, password: this.password };
    },
    icon() {
      return this.$route.meta.icon || "";
    },
    storeAction() {
      return this.$route.meta.storeAction || "";
    },
    store() {
      return this.$route.meta.store || "";
    },
    title() {
      return this.$route.meta.title || "";
    },
    namespace() {
      return this.$route.meta.namespace || "";
    },
    user() {
      return JSON.parse(this.$auth.remember()) || {};
    },
    formData() {
      let form = [
        {
          text: "Full Name*",
          value: "name",
          flex: "col-sm-12 col-md-12",
          required: "required",
          class: "",
          validate: "required"
        },
        {
          text: "Email*",
          value: "email",
          flex: "col-sm-12 col-md-6",
          required: "required",
          class: "",
          validate: "required"
        },
        {
          text: "Password*",
          value: "password",
          password: true,
          generator: false,
          flex: "col-sm-12 col-md-6",
          required: "",
          class: "",
          validate: ""
        }
      ];
      return form;
    }
  }
};
</script>
