import Vue from "vue";
const setItemsData = ( state, value ) => {
	state.items = value.data ? value.data : value
	state.total = value.total ? value.total : state.total;
};

const setItemData = ( state, value ) => {
	state.item = value.data ? value.data : state.item;
};

const setLoading = ( state, value ) => {
	state.loading = value;
};

const setPagination = ( state, value ) => {
	Vue.set( state, "pagination", Object.assign( state.pagination, value ) );
};

export default {
	setItemsData,
	setItemData,
	setPagination,
	setLoading
};
