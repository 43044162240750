const items = state => state.items;
const total = state => state.total;
const item = state => state.item;
const loading = state => state.loading;
const pagination = state => state.pagination;
const activityLog = state => state.activityLog;

export default {
	items,
	total,
	item,
	loading,
	activityLog,
	pagination
};
