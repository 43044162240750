import RestApi from "@/api/RestApi";

export default class CommentsApi extends RestApi {
	constructor() {
		super();
		this.baseEndpoint = this.domain;
		this.sufix = "/comments/";
	}

	async getList(pagination) {
		let url = this.baseEndpoint;
		let response = await this.getData(url, pagination);
		return response;
	}

	async getItem(item) {
		let url = this.baseEndpoint + item.type + "/" + item.typeId + "/comments/";
		let response = await this.getData(url);
		return response;
	}

	async insertItem(item) {
		let url = this.baseEndpoint + item.type + "/" + item.typeId + "/comment/";
		let response = await this.insertData(url, item);
		return response;
	}

	async updateItem(id, item) {
		let url = this.baseEndpoint + "comments/" + id;
		let response = await this.insertData(url, item);
		return response;
	}

	async deleteItem(id) {
		let url = this.baseEndpoint + "comments/" + id + "/delete";
		let response = await this.deleteData(url);
		return response;
	}
	async seenStatus(item){
		let url = this.baseEndpoint + "comments/status/seen";
		let response = await this.insertData(url, item);
		return response;
	}
}
