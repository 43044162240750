import Vue from "vue";
import "@/plugins/axios";
import App from "./App.vue";
import vuetify from "@/plugins/vuetify";
import pusher from "@/plugins/pusher";
import store from "@/store";
import router from "@/router";
window.Event = new Vue();
Vue.config.productionTip = false;
import auth from "@/plugins/auth";
Vue.use(require("vue-moment"));
new Vue({
	vuetify,
	store,
	router,
	render: h => h(App)
}).$mount("#app");
