<template>
  <v-container></v-container>
</template>
<script>
export default {
  name: "WorkForceTypesShow",
  data() {
    return {};
  },
  created() {},
  computed: {}
};
</script>
