import View from "@/views/CompanyStatus.vue";
import Index from "@/components/companyStatus/List.vue";
import Create from "@/components/companyStatus/Create.vue";
import Edit from "@/components/companyStatus/Edit.vue";
import Show from "@/components/companyStatus/Show.vue";
import store from "@/store";

export default [
	{
		path: "/company-status",
		name: "companyStatus",
		component: View,
		title: "Company Status",
		redirect: {
			name: "list-companyStatus"
		},
		children: [
			{
				path: "list",
				name: "list-companyStatus",
				meta: {
					icon: "mdi-briefcase-account"
				},
				beforeEnter: (to, from, next) => {
					store.commit("options/setOptionsData", {
						dialog: false
					});
					next();
				},
				components: {
					page: Index
				}
			},
			{
				path: "create",
				name: "create-companyStatus",
				meta: {
					storeAction: "companyStatus/insertItem",
					namespace: "companyStatus",
					store: "companyStatus",
					title: "Create Company status",
					icon: "mdi-briefcase-account"
				},
				beforeEnter: (to, from, next) => {
					store.commit("options/setOptionsData", {
						dialog: true
					});
					next();
				},
				components: {
					modal: Create
				}
			},
			{
				path: "edit/:id",
				name: "edit-companyStatus",
				meta: {
					storeAction: "companyStatus/updateItem",
					namespace: "companyStatus",
					store: "companyStatus",
					title: "Edit Company Status",
					icon: "mdi-briefcase-account"
				},
				beforeEnter: (to, from, next) => {
					store.commit("options/setOptionsData", {
						dialog: true
					});
					next();
				},
				components: {
					modal: Edit
				}
			},
			{
				path: "show/:id",
				name: "show-companyStatus",
				components: {
					page: Show
				}
			}
		]
	}
];
