<template>
  <v-container fluid>
    <v-row class="px-10">
      <v-col cols="12" clas="text-left">
        <div class="primary--text display-1  font-weight-bold  py-3">
          SIGN UP
        </div>

        <div class="grey--text text--darken-2 body-1 my-2">
          If you would like to partner with DEKRA Arbeit Group and have more
          info about Master Vendor solution, please fill this form.
        </div>
      </v-col>
      <v-col cols="12" md="12"> <v-divider></v-divider></v-col>
    </v-row>

    <ValidationObserver ref="observer" v-slot="{ invalid }">
      <v-form id="signUpForm" class="fill-height">
        <v-container fluid class="fill-height pa-10">
          <v-row>
            <v-col cols="12" md="3">
              <ValidationProvider
                v-slot="{ errors }"
                name="First Name"
                rules="required"
              >
                <v-text-field
                  v-model="firstName"
                  :error-messages="errors"
                  required
                  name="First Name"
                  label="First Name"
                  type="text"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" md="3">
              <ValidationProvider
                v-slot="{ errors }"
                name="Last Name"
                rules="required"
              >
                <v-text-field
                  v-model="lastName"
                  :error-messages="errors"
                  required
                  name="Last Name"
                  label="Last Name"
                  type="text"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" md="3">
              <ValidationProvider
                v-slot="{ errors }"
                name="Phone"
                rules="required"
              >
                <v-text-field
                  v-model="phone"
                  :error-messages="errors"
                  required
                  name="Phone"
                  label="Phone number"
                  type="text"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" md="3">
              <ValidationProvider
                v-slot="{ errors }"
                name="Email"
                rules="required"
              >
                <v-text-field
                  v-model="email"
                  :error-messages="errors"
                  required
                  name="Email"
                  label="Email"
                  type="text"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" md="12"> <v-divider></v-divider></v-col>
            <v-col cols="12" md="4">
              <ValidationProvider
                v-slot="{ errors }"
                name="Country"
                rules="required"
              >
                <v-text-field
                  v-model="country"
                  :error-messages="errors"
                  required
                  name="Country"
                  label="Country"
                  type="text"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" md="4">
              <ValidationProvider
                v-slot="{ errors }"
                name="Company"
                rules="required"
              >
                <v-text-field
                  v-model="company"
                  :error-messages="errors"
                  required
                  name="Company"
                  label="Company"
                  type="text"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" md="4">
              <ValidationProvider
                v-slot="{ errors }"
                name="Job Position"
                rules="required"
              >
                <v-text-field
                  v-model="jobPosition"
                  :error-messages="errors"
                  required
                  name="Job Position"
                  label="Job Position"
                  type="text"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" md="12"> <v-divider></v-divider></v-col>
            <v-col cols="12" md="12">
              <ValidationProvider
                v-slot="{ errors }"
                name="Message"
                rules="required"
              >
                <v-textarea
                  v-model="message"
                  :error-messages="errors"
                  required
                  outlined
                  name="Message"
                  label="Message"
                ></v-textarea>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" md="12"> <v-divider></v-divider></v-col>
            <v-col cols="12" class="text-right justify align ">
              <v-btn
                tile
                block
                color="primary"
                :disabled="invalid"
                :loading="loading"
                @click="submitForm"
                class="text-transform"
                >SEND
                <v-icon small right>mdi-exit-to-app</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </ValidationObserver>
  </v-container>
</template>
<script>
import { required } from "vee-validate/dist/rules";
import { mapGetters } from "vuex";
import ContactApi from "@/api/contact";
const restApi = new ContactApi();
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode
} from "vee-validate";
setInteractionMode("eager");
extend("required", {
  ...required,
  message: "{_field_} can not be empty"
});
export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      firstName: null,
      lastName: null,
      phone: null,
      email: null,
      country: null,
      company: null,
      jobPosition: null,
      message: null,
      text: ""
    };
  },
  computed: {
    ...mapGetters({
      loading: "options/loading"
    })
  },
  methods: {
    async submitForm() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        const formData = {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          phone: this.phone,
          company: this.company,
          country: this.country,
          jobPosition: this.jobPosition,
          message: this.message
        };
        try {
          const response = await restApi.signUp(formData);
          this.firstName = null;
          this.lastName = null;
          this.email = null;
          this.phone = null;
          this.company = null;
          this.country = null;
          this.jobPosition = null;
          this.message = "";
          //  console.log(response);
        } catch (error) {
          console.log(error);
        }
      }
    }
  }
};
</script>
