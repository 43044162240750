import RestApi from "@/api/RestApi";

export default class JobPositionsApi extends RestApi {
	constructor() {
		super();
		this.baseEndpoint = this.domain + "job-positions/";
	}

	async getList(pagination) {
		let url = this.baseEndpoint;
		let response = await this.getData(url, pagination);
		return response;
	}

	async getItem(id) {
		let url = this.baseEndpoint + id + '/edit';
		let response = await this.getData(url);
		return response;
	}

	async insertItem(item) {
		let url = this.baseEndpoint;
		let response = await this.insertData(url, item);
		return response;
	}

	async updateItem(id, item) {
		let url = this.baseEndpoint + id;
		let response = await this.putData(url, item);
		return response;
	}

	async deleteItem(id) {
		let url = this.baseEndpoint + id;
		let response = await this.deleteData(url);
		return response;
	}
}
