import View from "@/views/Log.vue";
import Index from "@/components/logs/List.vue";
import Create from "@/components/logs/Create.vue";
import Edit from "@/components/logs/Edit.vue";
import Show from "@/components/logs/Show.vue";
import store from "@/store";

export default [
	{
		path: "/logs",
		name: "logs",
		component: View,
		redirect: {
			name: "list-log"
		},
		children: [
			{
				path: "list",
				name: "list-log",
				meta: {
					icon: "mdi-math-log"
				},
				beforeEnter: (to, from, next) => {
					store.commit("options/setOptionsData", {
						dialog: false
					});
					next();
				},
				components: {
					page: Index
				}
			},
			{
				path: "create",
				name: "create-log",
				beforeEnter: (to, from, next) => {
					store.commit("options/setOptionsData", {
						dialog: true
					});
					next();
				},
				components: {
					modal: Create
				}
			},
			{
				path: "edit/:id",
				name: "edit-log",
				beforeEnter: (to, from, next) => {
					store.commit("options/setOptionsData", {
						dialog: true
					});
					next();
				},
				components: {
					modal: Edit
				}
			},
			{
				meta: {
					namespace: "log",
					title: "Logs",
					store: "logs",
					icon: "mdi-math-log"
				},
				beforeEnter: (to, from, next) => {
					store.commit("options/setOptionsData", {
						dialog: false
					});
					next();
				},
				path: "show/:id",
				name: "show-log",
				components: {
					page: Show
				}
			}
		]
	}
];
