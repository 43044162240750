import RestApi from "@/api/RestApi";

export default class ReportsApi extends RestApi {
	constructor() {
		super();
		this.baseEndpoint = this.domain + "reports/bmw/";
	}

	async getList() {
		let url = this.baseEndpoint;
		let response = await this.getData(url);
		return response;
	}

	async generateReport(data) {
		let url = this.baseEndpoint;
		let response = await this.insertData(url);
		return response;
	}

	async deleteItem(id) {
		let url = this.baseEndpoint + id;
		let response = await this.deleteData(url);
		return response;
	}
}
