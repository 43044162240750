import Vue from "vue";
const setItemsData = (state, value) => {
	state.items = value.data ? value.data : value;
	state.total = value.total ? value.total : state.total;
};

const setItemData = (state, value) => {
	state.item = value.data ? value.data : state.item;
};

const resetItemData = (state, value) => {
	state.item = [];
};

const setLoading = (state, value) => {
	state.loading = value;
};

const setPagination = (state, value) => {
	Vue.set(state, "pagination", Object.assign(state.pagination, value));
};

const pushItemToList = (state, value) => {
	state.item.unshift(value);
};

const updateList = (state, value) => {
	const elementsIndex = state.item.findIndex(element => element.id == value.id);
	let newArray = [...state.item];
	newArray[elementsIndex] = {
		...newArray[elementsIndex],
		comment: value.comment
	};
	state.item = newArray;
};

export default {
	setItemsData,
	setItemData,
	resetItemData,
	setPagination,
	pushItemToList,
	updateList,
	setLoading
};
