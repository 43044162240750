import View from "@/views/WorkforceRequest.vue";
import Index from "@/components/workforceRequests/List.vue";
import Create from "@/components/workforceRequests/Create.vue";
import Edit from "@/components/workforceRequests/Edit.vue";
import Show from "@/components/workforceRequests/Show.vue";
import store from "@/store";

export default [
	{
		path: "/workforce",
		name: "workforce",
		component: View,
		title: "Workforce Request",
		meta: {
			textColor: "pink--text text--accent-4",
			color: "pink accent-4",
			colorHex: "#c5007b",
			icon: "mdi-lan"
		},
		redirect: {
			name: "list-workforce"
		},
		children: [
			{
				path: "list",
				name: "list-workforce",
				meta: {
					textColor: "pink--text text--accent-4",
					color: "pink accent-4",
					colorHex: "#c5007b",
					icon: "mdi-lan"
				},
				beforeEnter: (to, from, next) => {
					store.commit("options/setOptionsData", {
						dialog: false
					});
					next();
				},
				components: {
					page: Index
				}
			},
			{
				path: "create",
				name: "create-workforce",
				meta: {
					storeAction: "workforce/insertItem",
					namespace: "workforce",
					store: "workforce",
					textColor: "pink--text text--accent-4",
					color: "pink accent-4",
					colorHex: "#c5007b",
					title: "Create Workforce Request",
					icon: "mdi-lan"
				},
				props: route => ({ goTo: route.query.goTo }),
				beforeEnter: (to, from, next) => {
					store.commit("options/setOptionsData", {
						dialog: true
					});
					next();
				},
				components: {
					modal: Create
				}
			},
			{
				path: "edit/:id",
				name: "edit-workforce",
				meta: {
					storeAction: "workforce/updateItem",
					namespace: "workforce",
					textColor: "pink--text text--accent-4",
					color: "pink accent-4",
					colorHex: "#c5007b",
					store: "workforce",
					title: "Edit Workforce Request",
					icon: "mdi-lan"
				},
				beforeEnter: (to, from, next) => {
					store.commit("options/setOptionsData", {
						dialog: true
					});
					next();
				},
				components: {
					modal: Edit
				}
			},
			{
				path: "show/:id",
				meta: {
					namespace: "workforce",
					title: "Workforce request",
					store: "workforce",
					textColor: "pink--text text--accent-4",
					color: "pink accent-4",
					colorHex: "#c5007b",
					icon: "mdi-lan"
				},
				beforeEnter: (to, from, next) => {
					store.commit("options/setOptionsData", {
						dialog: false
					});
					next();
				},
				name: "show-workforce",
				components: {
					page: Show
				}
			}
		]
	}
];
