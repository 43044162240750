import View from "@/views/FileType.vue";
import Index from "@/components/fileTypes/List.vue";
import Create from "@/components/fileTypes/Create.vue";
import Edit from "@/components/fileTypes/Edit.vue";
import Show from "@/components/fileTypes/Show.vue";
import store from "@/store";

export default [
	{
		path: "/file-type",
		name: "fileType",
		component: View,
		title: "File Type",
		redirect: {
			name: "list-fileType"
		},
		children: [
			{
				path: "list",
				name: "list-fileType",
				meta: {
					icon: "mdi-file-word-box"
				},
				beforeEnter: (to, from, next) => {
					store.commit("options/setOptionsData", {
						dialog: false
					});
					next();
				},
				components: {
					page: Index
				}
	},
			{
				path: "create",
				name: "create-fileType",
				meta: {
					storeAction: "fileTypes/insertItem",
					namespace: "fileType",
					store: "fileTypes",
					title: "Create File types",
					icon: "mdi-file-word-box"
				},
				beforeEnter: (to, from, next) => {
					store.commit("options/setOptionsData", {
						dialog: true
					});
					next();
				},
				components: {
					modal: Create
				}
	},
			{
				path: "edit/:id",
				name: "edit-fileType",
				meta: {
					storeAction: "fileTypes/updateItem",
					namespace: "fileType",
					store: "fileTypes",
					title: "Edit File Type",
					icon: "mdi-file-word-box"
				},
				beforeEnter: (to, from, next) => {
					store.commit("options/setOptionsData", {
						dialog: true
					});
					next();
				},
				components: {
					modal: Edit
				}
	},
			{
				path: "show/:id",
				name: "show-fileType",
				components: {
					page: Show
				}
	}]
}];
