export default {
	methods: {
		/**
		 * @param {string} type
		 * Is it link
		 */
		isLink(type) {
			try {
				new URL(type);
			} catch (_) {
				return false;
			}

			return true;
		},
	}
};
