import NotificationsApi from "@/api/notifications";
import ErrorHandling from "@/plugins/ErrorHandling";
const errorHandling = new ErrorHandling();
const restApi = new NotificationsApi();

const getItems = async ({ commit, state }, payload) => {
	try {
		commit("setLoading", true);
		const items = await restApi.getList(payload, payload.pagination);
		commit("setItemsData", items);
		commit("setLoading", false);
	} catch (error) {
		errorHandling.onError(error);
	}
};

const getNewNotifications = async ({ commit, state }, payload) => {
	try {
		commit("setLoadingNew", true);
		const items = await restApi.getUnseenList(payload);
		commit("setNewNotifications", items);
		commit("setLoadingNew", false);
	} catch (error) {
		errorHandling.onError(error);
	}
};

const setPagination = async ({ commit }, payload) => {
	commit("setPagination", payload);
};

const markAsRead = async ({ commit }, payload) => {
	try {
		await restApi.markAsRead(payload);
		commit("removeNewNotification");
	} catch (error) {
		errorHandling.onError(error);
	}
};

const markAllAsRead = async ({ commit }, payload) => {
	try {
		await restApi.markAllAsRead(payload);
	} catch (error) {
		errorHandling.onError(error);
	}
};

const addNewNotification = async ({ commit }, payload) => {
	try {
		commit("addNewNotification", payload);
	} catch (error) {
		errorHandling.onError(error);
	}
};

const deleteItem = async ({ commit }, item) => {
	try {
		commit("setLoading", true);
		await restApi.deleteItem(item);
		commit("setLoading", false);
	} catch (error) {
		errorHandling.onError(error);
	}
};

export default {
	getNewNotifications,
	getItems,
	addNewNotification,
	markAllAsRead,
	markAsRead,
	setPagination,
	deleteItem
};
