<template>
  <v-container fluid class="pa-0">
    <router-view name="page" />
    <modal-component v-if="dialog" :dialog.sync="dialog" module="productivity">
    </modal-component>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "Productivity",
  data() {
    return {};
  },
  components: {
    ModalComponent: () => import("@/components/core/ModalComponent")
  },
  computed: {
    ...mapGetters({
      dialog: "options/getDialog"
    })
  }
};
</script>
