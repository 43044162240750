<template>
  <v-container fluid>
    <v-row justify="space-around" no-gutters>
      <v-col cols="12">
        <v-sheet color="grey lighten-5" class="pa-12" v-if="item">
          <v-row align="start" justify="end">
            <v-col cols="8" class=" text-left">
              <div class="d-flex">
                <v-avatar size="100" v-if="icon">
                  <v-icon :color="color" size="85" left>{{ icon }}</v-icon>
                </v-avatar>
                <div class="ma-2">
                  <span
                    class="grey--text text--darken-2 mb-2 display-1 d-flex"
                    v-if="item.name"
                  >
                    {{ item.name }}</span
                  >
                </div>
              </div>
              <h2 class=" grey--text text--darken-1 ma-2 title">
                <span v-if="item.company">{{ item.company.name }}</span>
              </h2>
            </v-col>
            <v-col class="text-right">
              <v-btn
                v-if="userCan('edit-' + [routeName])"
                class="primary--text"
                text
                large
                :to="{ name: 'edit-' + [routeName], params: { id: id } }"
              >
                <v-icon left color="primary">mdi-pencil</v-icon>Edit
              </v-btn>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
      <v-col cols="12">
        <v-tabs
          v-model="tab"
          :background-color="color"
          :active-class="'grey lighten-5 ' + textColor"
          grow
          dark
          icons-and-text
        >
          <v-tabs-slider color="transparent"></v-tabs-slider>

          <v-tab href="#tab-1">
            General
            <v-icon>mdi-information-outline</v-icon>
          </v-tab>
          <v-tab href="#tab-2" v-if="userCan('list-file')">
            Files
            <v-icon>mdi-file-multiple</v-icon>
          </v-tab>
          <v-tab href="#tab-3" v-if="userCan('read-comments-candidate')">
            Comments
            <v-badge
              :content="item.comments_count"
              :value="item.comments_count"
              overlap
            >
              <v-icon>mdi-comment-text-multiple</v-icon>
            </v-badge>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item :value="'tab-1'">
            <general-tab :item="item"></general-tab>
          </v-tab-item>
          <v-tab-item :value="'tab-2'" v-if="userCan('list-file')">
            <file-list
              v-if="item.files"
              :show-data-only="true"
              :passed-data="item.files"
            ></file-list>
          </v-tab-item>
          <v-tab-item
            :value="'tab-3'"
            v-if="userCan('read-comments-candidate')"
          >
            <comment-list type="employees" :typeId="id"></comment-list>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import date from "@/mixins/date";
import permission from "@/mixins/permission";
export default {
  name: "CandidateShow",
  mixins: [date, permission],
  components: {
    GeneralTab: () => import("@/components/candidates/tabs/GeneralTab"),
    FileList: () => import("@/components/files/List"),
    CommentList: () => import("@/components/comments/List")
  },
  data() {
    return {
      item: {},
      routeName: "candidate",
      tab: null
    };
  },
  created() {},
  async mounted() {
    const _this = this;
    this.$store.dispatch("files/setPagination", {
      employee_id: _this.id
    });
    _this.$store.dispatch(_this.store + "/showItem", _this.id).then(() => {
      _this.item = _this.$store.getters[_this.store + "/item"];
    });
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch("files/setPagination", { employee_id: null });
    next();
  },
  computed: {
    icon() {
      return this.$route.meta.icon || "";
    },
    id() {
      return this.$route.params.id || null;
    },
    textColor() {
      return this.$route.meta.textColor || "";
    },
    color() {
      return this.$route.meta.color || "";
    },
    store() {
      return this.$route.meta.store || "";
    },
    title() {
      return this.$route.meta.title || "";
    },
    namespace() {
      return this.$route.meta.namespace || "";
    }
  }
};
</script>
