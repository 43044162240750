import View from "@/views/CompanyType.vue";
import Index from "@/components/companyTypes/List.vue";
import Create from "@/components/companyTypes/Create.vue";
import Edit from "@/components/companyTypes/Edit.vue";
import Show from "@/components/companyTypes/Show.vue";
import store from "@/store";

export default [
	{
		path: "/company-type",
		name: "companyType",
		component: View,
		title: "Company Type",
		redirect: {
			name: "list-companyType"
		},
		children: [
			{
				path: "list",
				name: "list-companyType",
				meta: {
					icon: "mdi-briefcase-upload-outline"
				},
				beforeEnter: (to, from, next) => {
					store.commit("options/setOptionsData", {
						dialog: false
					});
					next();
				},
				components: {
					page: Index
				}
	},
			{
				path: "create",
				name: "create-companyType",
				meta: {
					storeAction: "companyTypes/insertItem",
					namespace: "companyType",
					store: "companyTypes",
					title: "Create Company types",
					icon: "mdi-briefcase-upload-outline"
				},
				beforeEnter: (to, from, next) => {
					store.commit("options/setOptionsData", {
						dialog: true
					});
					next();
				},
				components: {
					modal: Create
				}
	},
			{
				path: "edit/:id",
				name: "edit-companyType",
				meta: {
					storeAction: "companyTypes/updateItem",
					namespace: "companyType",
					store: "companyTypes",
					title: "Edit Company Type",
					icon: "mdi-briefcase-upload-outline"
				},
				beforeEnter: (to, from, next) => {
					store.commit("options/setOptionsData", {
						dialog: true
					});
					next();
				},
				components: {
					modal: Edit
				}
	},
			{
				path: "show/:id",
				name: "show-companyType",
				components: {
					page: Show
				}
	}]
}];
