import RestApi from "@/api/RestApi";

export default class ProductivityApi extends RestApi {
	constructor() {
		super();
		this.baseEndpoint = this.domain + "reports/productivity/";
	}

	async getList(pagination) {
		let url = this.baseEndpoint;
		let response = await this.getData(url, pagination);
		return response;
	}

	async getItem(id) {
		let url = this.baseEndpoint + id + "/edit";
		let response = await this.getData(url);
		return response;
	}

	async generateProductivity(data) {
		let url = this.baseEndpoint + "export-employees";
		let response = await this.insertData(url, data);
		return response;
	}

	async uploadProductivity(data) {
		let url = this.baseEndpoint + "import-productivity";
		let response = await this.insertData(url, data);
		return response;
	}
}
