<template>
  <productivity-list
    :icon="icon"
    :title="title"
    :route-name="routeName"
    :namespace="namespace"
  ></productivity-list>
</template>
<script>
export default {
  name: "ProductivityLists",
  components: {
    ProductivityList: () => import("@/components/core/lists/ProductivityList")
  },
  data() {
    return {
      routeName: "productivity",
      namespace: "productivity",
      title: "Productivity"
    };
  },
  computed: {
    icon() {
      return this.$route.meta.icon || "";
    }
  },
  created() {}
};
</script>
