<template>
  <file-list
    :icon="icon"
    :title="title"
    :route-name="routeName"
    :show-data-only="showDataOnly"
    :passed-data="passedData"
    :namespace="namespace"
  ></file-list>
</template>
<script>
export default {
  name: "FilesList",
  components: {
    FileList: () => import("@/components/core/lists/FileList")
  },
  props: {
    showDataOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    passedData: {
      type: Array,
      required: false,
      default: null
    }
  },
  data() {
    return {
      routeName: "file",
      namespace: "files",
      title: "Files"
    };
  },
  computed: {
    icon() {
      return this.$route.meta.icon || "";
    }
  },
  created() {}
};
</script>
