import RestApi from "@/api/RestApi";

export default class NotificationsApi extends RestApi {
	constructor() {
		super();
		this.baseEndpoint = this.domain + "notifications/";
	}

	async getList(payload) {
		let queryString = payload.unseen
			? "?seen=0&users=" + payload.id
			: "?users=" + payload.id;
		let url = this.baseEndpoint + queryString;
		let response = await this.getData(url, payload.pagination);
		return response;
	}

	async getUnseenList(payload) {
		let url = this.domain + "users/" + payload.id + "/notifications?seen=0";
		let response = await this.getData(url);
		return response;
	}

	async markAsRead(payload) {
		let url =
			this.baseEndpoint + payload.id + "/seen" + "/?user_id=" + payload.user_id;
		let response = await this.insertData(url);
		return response;
	}

	async markAllAsRead(payload) {
		let url =
			this.domain + "users/" + payload.user_id + "/notifications/seen/bulk";
		let response = await this.insertData(url, {
			notifications: payload.notifications
		});
		return response;
	}

	async deleteItem(id) {
		let url = this.baseEndpoint + id;
		let response = await this.deleteData(url);
		return response;
	}
}
