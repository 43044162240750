<template>
  <v-container fluid class="px-5 fill-height">
    <v-col class="px-5 flex-column layout">
      <v-row class="layout">
        <v-col xs="12" sm="12" md="12">
          <v-row class="dekra-border-bottom-list pa-0">
            <v-icon large class="green--text">mdi-magnify</v-icon>
            <div class="dekra-headline headline grey--text text--darken-3 font-weight-bold pa-1 pl-2">Keyword - <span
                class="font-italic">{{ search }}</span></div>
            <v-spacer></v-spacer>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="layout  py-5">
        <show-tab v-if="companies.length" filter-id="search" :current-route="getRoutes('company')" route-name="company" namespace="companies"
                  :data-list="companies"
                  :item-id="search"></show-tab>
        <show-tab v-if="workforce.length" filter-id="search" :current-route="getRoutes('workforce')" route-name="workforce" namespace="workforce"
                  :data-list="workforce"
                  :item-id="search"></show-tab>
        <show-tab v-if="employees.length" :current-route="getRoutes('employee')" route-name="employee" namespace="employees" :data-list="employees"></show-tab>
        <show-tab v-if="candidates.length" :current-route="getRoutes('candidate')" route-name="candidate" namespace="candidates" :data-list="candidates"></show-tab>
      </v-row>
    </v-col>
  </v-container>
</template>
<script>
import showTab from '@/components/core/tabs/showTab.vue'
import {mapGetters} from "vuex";

export default {
  name: 'Search',
  components: {
    showTab,
  },
  data() {
    return {};
  },
  async mounted() {
    await this.$store.dispatch("companies/getItems", {search: this.search});
    await this.$store.dispatch("workforce/getItems", {search: this.search});
    await this.$store.dispatch("employees/getItems", {search: this.search});
    await this.$store.dispatch("candidates/getItems", {search: this.search});
  },
  computed: {
    ...mapGetters({
      companies: "companies/items",
      workforce: "workforce/items",
      employees: "employees/items",
      candidates: "candidates/items"
    }),
    search() {
      return this.$route.params.search
    }
  },
  methods: {
    getRoutes(namespace) {
      let dashboardRoute = this.$router.options.routes[2];
      return  dashboardRoute.children.find((route) => route.name === namespace);
    },
  }
};

</script>
<style lang="scss" scoped>
.dekra-border-bottom-list {
  border-bottom: 1px solid #888888;
}
</style>