import View from "@/views/JobPosition.vue";
import Index from "@/components/jobPositions/List.vue";
import Create from "@/components/jobPositions/Create.vue";
import Edit from "@/components/jobPositions/Edit.vue";
import Show from "@/components/jobPositions/Show.vue";
import store from "@/store";

export default [
	{
		path: "/job-position",
		name: "jobPosition",
		component: View,
		title: "Job Position",
		redirect: {
			name: "list-jobPosition"
		},
		children: [
			{
				path: "list",
				name: "list-jobPosition",
				meta: {
					icon: "mdi-account-hard-hat"
				},
				beforeEnter: (to, from, next) => {
					store.commit("options/setOptionsData", {
						dialog: false
					});
					next();
				},
				components: {
					page: Index
				}
	},
			{
				path: "create",
				name: "create-jobPosition",
				meta: {
					storeAction: "jobPositions/insertItem",
					namespace: "jobPosition",
					store: "jobPositions",
					title: "Create Job Position",
					icon: "mdi-account-hard-hat"
				},
				beforeEnter: (to, from, next) => {
					store.commit("options/setOptionsData", {
						dialog: true
					});
					next();
				},
				components: {
					modal: Create
				}
	},
			{
				path: "edit/:id",
				name: "edit-jobPosition",
				meta: {
					storeAction: "jobPositions/updateItem",
					namespace: "jobPosition",
					store: "jobPositions",
					title: "Edit Job Position",
					icon: "mdi-account-hard-hat"
				},
				beforeEnter: (to, from, next) => {
					store.commit("options/setOptionsData", {
						dialog: true
					});
					next();
				},
				components: {
					modal: Edit
				}
	},
			{
				path: "show/:id",
				name: "show-jobPosition",
				components: {
					page: Show
				}
	}]
}];
