import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import moment from "moment";

const state = {
	items: [],
	total: 0,
	activityLog: {},
	item: {},
	loading: false,
	pagination: {
		page: 1,
		date_from: moment()
			.subtract(1, "months")
			.toISOString()
			.substr(0, 7),
		date_to: moment()
			.toISOString()
			.substr(0, 7),
		hr_supplier_id: null,
		rowsPerPage: 12,
		loadRows: 12
	}
};

export default {
	namespaced: true,
	state,
	actions,
	getters,
	mutations
};
