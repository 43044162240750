import Vue from "vue";

import auth from "@websanova/vue-auth/src/v2.js";
import driverAuthBearer from "@websanova/vue-auth/src/drivers/auth/bearer.js";
import driverHttpAxios from "@websanova/vue-auth/src/drivers/http/axios.1.x.js";
// import driverHttpVueResource from '@websanova/vue-auth/src/drivers/http/vue-resource.1.x.js';
import driverRouterVueRouter from "@websanova/vue-auth/src/drivers/router/vue-router.2.x.js";
Vue.use(auth, {
	plugins: {
		http: Vue.axios, // Axios
		// http: Vue.http, // Vue Resource
		router: Vue.router
	},
	drivers: {
		auth: driverAuthBearer,
		http: driverHttpAxios, // Axios
		router: driverRouterVueRouter
	},
	options: {
		fetchData: {
			enabled: true,
			url: "me",
			method: "GET"
		},
		_parseUserData: function(data) {
			return data.result.user;
		},
		loginData: {
			url: "login",
			method: "POST",
			redirect: "dashboard",
			fetchUser: false
		},
		logoutData: {
			url: "logout",
			method: "POST",
			redirect: "/",
			makeRequest: true
		},
		rememberKey: "user",
		rolesKey: "permissions",
		tokenDefaultKey: "token",
		stores: ["storage"],
		authRedirect: "/",
		refreshData: { enabled: false, interval: 180 },
		notFoundRedirect: { name: "dashboard" }
	}
});
