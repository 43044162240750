import DashboardApi from "@/api/dashboard";
import ErrorHandling from "@/plugins/ErrorHandling";
const errorHandling = new ErrorHandling();
const restApi = new DashboardApi();

const getHrSuppliers = async ({ commit, state }) => {
	try {
		commit("setLoading", true);
		const options = JSON.parse(JSON.stringify(state.pagination));
		Object.keys(options).forEach(function() {
			options["perPage"] = options["rowsPerPage"];
			options["orderDir"] = options["sortDesc"] ? "desc" : "asc";
		});
		const items = await restApi.getHRSuppliers();

		commit("setHrSuppliers", items);
		commit("setLoading", false);
	} catch (error) {
		errorHandling.onError(error);
	}
};

const setPagination = async ({ commit }, payload) => {
	commit("setPagination", payload);
};

export default {
	getHrSuppliers,
	setPagination
};
