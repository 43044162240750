<template>
  <v-app id="app">
    <router-view />
    <snackbar></snackbar>
  </v-app>
</template>
<script>
import commentNotifications from "./mixins/commentNotifications";

export default {
  mixins: [commentNotifications],
  components: {
    Snackbar: () => import("@/components/core/Snackbar")
  },
  computed: {
    _user() {
      return JSON.parse(this.$auth.remember()) || {};
    }
  },
  methods: {
    async getNotifications() {
      await this.$store.dispatch("notifications/getNewNotifications", {
        id: this._user.id,
        unseen: true
      });
    }
  },
  created() {
    const channel = this.$pusher.subscribe("my-channel");
    channel.bind("my-event", data => {
      const affectedUsers = Array.isArray(data.affectedUsers)
        ? data.affectedUsers
        : Object.values(data.affectedUsers);
      if (affectedUsers.includes(this._user.id)) {
        this.emitNotification(data);
        this.$store.commit("options/setOptionsData", {
          message: data.message,
          snackbar: true,
          color: "success"
        });
        this.$store
          .dispatch("notifications/addNewNotification", data)
          .then(() => this.getNotifications());
      }
    });
  }
};
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
