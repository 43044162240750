const hrSuppliers = state => state.hrSuppliers;
const hrSuppliersTotal = state => state.hrSuppliersTotal;
const hrSuppliersHeaders = state => state.hrSuppliersHeaders;
const loading = state => state.loading;
const pagination = state => state.pagination;

export default {
	hrSuppliers,
	hrSuppliersTotal,
	hrSuppliersHeaders,
	loading,
	pagination
};
