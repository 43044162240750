import View from "@/views/WorkforceType.vue";
import Index from "@/components/workforceTypes/List.vue";
import Create from "@/components/workforceTypes/Create.vue";
import Edit from "@/components/workforceTypes/Edit.vue";
import Show from "@/components/workforceTypes/Show.vue";
import store from "@/store";

export default [
	{
		path: "/workforce-type",
		name: "workforceType",
		component: View,
		title: "Workforce Type",
		redirect: {
			name: "list-workforceType"
		},
		children: [
			{
				path: "list",
				name: "list-workforceType",
				meta: {
					icon: "mdi-lan"
				},
				beforeEnter: (to, from, next) => {
					store.commit("options/setOptionsData", {
						dialog: false
					});
					next();
				},
				components: {
					page: Index
				}
	},
			{
				path: "create",
				name: "create-workforceType",
				meta: {
					storeAction: "workforceTypes/insertItem",
					namespace: "workforceType",
					store: "workforceTypes",
					title: "Create Workforce Type",
					icon: "mdi-lan"
				},
				beforeEnter: (to, from, next) => {
					store.commit("options/setOptionsData", {
						dialog: true
					});
					next();
				},
				components: {
					modal: Create
				}
	},
			{
				path: "edit/:id",
				name: "edit-workforceType",
				meta: {
					storeAction: "workforceTypes/updateItem",
					namespace: "workforceType",
					store: "workforceTypes",
					title: "Edit Workforce Type",
					icon: "mdi-lan"
				},
				beforeEnter: (to, from, next) => {
					store.commit("options/setOptionsData", {
						dialog: true
					});
					next();
				},
				components: {
					modal: Edit
				}
	},
			{
				path: "show/:id",
				name: "show-workforceType",
				components: {
					page: Show
				}
	}]
}];
