export default {
    methods: {
        /**
         * @param {Object} data
         */
        emitNotification(data) {
            if (data.subjectModel.commentator.id !== this._user.id) {
                switch (data.objectAlias) {
                    case "comment-wfr":
                        this.$root.$emit("wfr-new-comment", data);
                        break;
                    case "comment-company":
                        this.$root.$emit("company-new-comment", data);
                        break;
                    case "comment-employee":
                        this.$root.$emit("employee-new-comment", data);
                        break;
                    case "comment-candidate":
                        this.$root.$emit("candidate-new-comment", data);
                        break;
                    default:
                        return;
                }
            }
        }
    }
};
