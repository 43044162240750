import EmployeesApi from "@/api/employees";
import ErrorHandling from "@/plugins/ErrorHandling";
const errorHandling = new ErrorHandling();
const restApi = new EmployeesApi();

const getItems = async ({ commit, state }, all = false) => {
	try {
		commit("setLoading", true);
		commit("setEmptyItems");
		const options = JSON.parse(JSON.stringify(state.pagination));
		Object.keys(options).forEach(function() {
			options["perPage"] = options["rowsPerPage"];
			options["orderDir"] = options["sortDesc"] ? "desc" : "asc";
		});
		const items = await restApi.getList(all ? all : options);
		commit("setItemsData", items);
		commit("setLoading", false);
	} catch (error) {
		errorHandling.onError(error);
	}
};

const assignEmployee = async ({ commit, dispatch }, item) => {
	try {
		commit("setLoading", true);
		await restApi.assignEmployee(item);
		dispatch("availableEmployees", { all: true });
		commit("setLoading", false);
	} catch (error) {
		errorHandling.onError(error);
	}
};

const unassignEmployee = async ({ commit, dispatch }, item) => {
	try {
		commit("setLoading", true);
		await restApi.unassignEmployee(item);
		dispatch("availableEmployees", { all: true });
		commit("setLoading", false);
	} catch (error) {
		errorHandling.onError(error);
	}
};

const terminateEmployee = async ({ commit, dispatch }, item) => {
	try {
		commit("setLoading", true);
		await restApi.terminateEmployee(item);
		//dispatch("availableEmployees", { all: true });
		commit("setLoading", false);
	} catch (error) {
		errorHandling.onError(error);
	}
};

const availableEmployees = async ({ commit }, item) => {
	try {
		commit("setLoading", true);
		const items = await restApi.availableEmployees();
		commit("setAvailableData", items);
		commit("setLoading", false);
	} catch (error) {
		errorHandling.onError(error);
	}
};

const terminationReasons = async ({ commit }, item) => {
	try {
		commit("setLoading", true);
		const items = await restApi.terminationReasons();
		commit("setTerminationReasonsData", items);
		commit("setLoading", false);
	} catch (error) {
		errorHandling.onError(error);
	}
};

const getActivityLog = async ({ commit }, itemId) => {
	commit("setLoading", true);
	try {
		const item = await restApi.getActivityLog(itemId);
		commit("setActivityLog", item);
		commit("setLoading", false);
	} catch (error) {
		errorHandling.onError(error, { itemId });
	}
};

const setPagination = async ({ commit }, payload) => {
	commit("setPagination", payload);
};

const getItem = async ({ commit }, itemId) => {
	try {
		commit("setLoading", true);
		const item = await restApi.getItem(itemId);
		commit("setItemData", item);
		commit("setLoading", false);
	} catch (error) {
		errorHandling.onError(error);
	}
};

const showItem = async ({ commit }, itemId) => {
	try {
		commit("setLoading", true);
		const item = await restApi.showItem(itemId);
		commit("setItemData", item);
		commit("setLoading", false);
	} catch (error) {
		errorHandling.onError(error);
	}
};

const insertItem = async ({ commit }, item) => {
	try {
		commit("setLoading", true);
		await restApi.insertItem(item);
		commit("setLoading", false);
	} catch (error) {
		errorHandling.onError(error);
	}
};

const updateItem = async ({ commit }, item) => {
	try {
		commit("setLoading", true);
		await restApi.updateItem(item.id, item);
		commit("setLoading", false);
	} catch (error) {
		errorHandling.onError(error);
	}
};

const deleteItem = async ({ commit }, item) => {
	try {
		commit("setLoading", true);
		await restApi.deleteItem(item);
		commit("setLoading", false);
	} catch (error) {
		errorHandling.onError(error);
	}
};

export default {
	getItems,
	getItem,
	showItem,
	assignEmployee,
	getActivityLog,
	unassignEmployee,
	terminateEmployee,
	terminationReasons,
	availableEmployees,
	setPagination,
	insertItem,
	updateItem,
	deleteItem
};
