import View from "@/views/Company.vue";
import Index from "@/components/companies/List.vue";
import Create from "@/components/companies/Create.vue";
import Edit from "@/components/companies/Edit.vue";
import Show from "@/components/companies/Show.vue";
import store from "@/store";

export default [
	{
		path: "/company",
		name: "company",
		component: View,
		title: "Company",
		meta: {
			icon: "mdi-briefcase-outline",
			textColor: "light-blue--text text--darken-1",
			color: "light-blue darken-1",
			colorHex:"#039be5"
		},
		redirect: {
			name: "list-company"
		},
		children: [
			{
				path: "list",
				name: "list-company",
				meta: {
					icon: "mdi-briefcase-outline",
					textColor: "light-blue--text text--darken-1",
					color: "light-blue darken-1"
				},
				beforeEnter: (to, from, next) => {
					store.commit("options/setOptionsData", {
						dialog: false
					});
					next();
				},
				components: {
					page: Index
				}
			},
			{
				path: "create",
				name: "create-company",
				meta: {
					storeAction: "companies/insertItem",
					namespace: "company",
					store: "companies",
					textColor: "light-blue--text text--darken-1",
					color: "light-blue darken-1",
					colorHex: "#039be5",
					title: "Create Company",
					icon: "mdi-briefcase-outline"
				},
				beforeEnter: (to, from, next) => {
					store.commit("options/setOptionsData", {
						dialog: true
					});
					next();
				},
				components: {
					modal: Create
				}
			},
			{
				path: "edit/:id",
				name: "edit-company",
				meta: {
					storeAction: "companies/updateItem",
					namespace: "company",
					store: "companies",
					textColor: "light-blue--text text--darken-1",
					color: "light-blue darken-1",
					colorHex: "#039be5",
					title: "Edit Company",
					icon: "mdi-briefcase-outline"
				},
				beforeEnter: (to, from, next) => {
					store.commit("options/setOptionsData", {
						dialog: true
					});
					next();
				},
				components: {
					modal: Edit
				}
			},
			{
				path: "show/:id",
				meta: {
					namespace: "company",
					title: "Company",
					store: "companies",
					textColor: "light-blue--text text--darken-1",
					color: "light-blue darken-1",
					colorHex: "#039be5",
					icon: "mdi-briefcase-outline"
				},
				beforeEnter: (to, from, next) => {
					store.commit("options/setOptionsData", {
						dialog: false
					});
					next();
				},
				name: "show-company",
				components: {
					page: Show
				}
			}
		]
	}
];
