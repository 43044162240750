import CandidatesApi from "@/api/candidates";
import ErrorHandling from "@/plugins/ErrorHandling";
const errorHandling = new ErrorHandling();
const restApi = new CandidatesApi();

const getItems = async ({ commit, state }, all = false) => {
	try {
		commit("setLoading", true);
		commit("setEmptyItems");
		const options = JSON.parse(JSON.stringify(state.pagination));
		Object.keys(options).forEach(function() {
			options["perPage"] = options["rowsPerPage"];
			options["orderDir"] = options["sortDesc"] ? "desc" : "asc";
		});
		const items = await restApi.getList(all ? all : options);
		commit("setItemsData", items);
		commit("setLoading", false);
	} catch (error) {
		errorHandling.onError(error);
	}
};

const getActivityLog = async ({ commit }, itemId) => {
	commit("setLoading", true);
	try {
		const item = await restApi.getActivityLog(itemId);
		commit("setActivityLog", item);
		commit("setLoading", false);
	} catch (error) {
		errorHandling.onError(error, { itemId });
	}
};

const setPagination = async ({ commit }, payload) => {
	commit("setPagination", payload);
};

const getItem = async ({ commit }, itemId) => {
	try {
		commit("setLoading", true);
		const item = await restApi.getItem(itemId);
		commit("setItemData", item);
		commit("setLoading", false);
	} catch (error) {
		errorHandling.onError(error);
	}
};

const showItem = async ({ commit }, itemId) => {
	try {
		commit("setLoading", true);
		const item = await restApi.showItem(itemId);
		commit("setItemData", item);
		commit("setLoading", false);
	} catch (error) {
		errorHandling.onError(error);
	}
};

const insertItem = async ({ commit }, item) => {
	try {
		commit("setLoading", true);
		await restApi.insertItem(item);
		commit("setLoading", false);
	} catch (error) {
		errorHandling.onError(error);
	}
};

const proposeCandidate = async ({ commit, dispatch }, item) => {
	try {
		commit("setLoading", true);
		await restApi.proposeCandidate(item);
		//dispatch("getItems", { all: true });
		commit("setLoading", false);
	} catch (error) {
		errorHandling.onError(error);
	}
};

const approve = async ({ commit }, item) => {
	try {
		commit("setLoading", true);
		await restApi.approve(item);
		commit("setLoading", false);
	} catch (error) {
		errorHandling.onError(error);
	}
};

const reject = async ({ commit }, item) => {
	try {
		commit("setLoading", true);
		await restApi.reject(item);
		commit("setLoading", false);
	} catch (error) {
		errorHandling.onError(error);
	}
};

const confirm = async ({ commit }, item) => {
	try {
		commit("setLoading", true);
		await restApi.confirm(item);
		commit("setLoading", false);
	} catch (error) {
		errorHandling.onError(error);
	}
};

const cancel = async ({ commit }, item) => {
	try {
		commit("setLoading", true);
		await restApi.cancel(item);
		commit("setLoading", false);
	} catch (error) {
		errorHandling.onError(error);
	}
};

const remove = async ({ commit }, item) => {
	try {
		commit("setLoading", true);
		await restApi.remove(item);
		commit("setLoading", false);
	} catch (error) {
		errorHandling.onError(error);
	}
};

const cancelTypes = async ({ commit }) => {
	try {
		const types = await restApi.getCancelTypes();
		commit("setCancelTypes", types);
	} catch (error) {
		errorHandling.onError(error);
	}
};

const attachFile = async ({ commit }, item) => {
	try {
		const types = await restApi.attachFile(item);
	} catch (error) {
		errorHandling.onError(error);
	}
};

const updateItem = async ({ commit }, item) => {
	try {
		commit("setLoading", true);
		await restApi.updateItem(item.id, item);
		commit("setLoading", false);
	} catch (error) {
		errorHandling.onError(error);
	}
};

const deleteItem = async ({ commit }, item) => {
	try {
		commit("setLoading", true);
		await restApi.deleteItem(item);
		commit("setLoading", false);
	} catch (error) {
		errorHandling.onError(error);
	}
};

export default {
	getItems,
	getItem,
	showItem,
	getActivityLog,
	setPagination,
	proposeCandidate,
	approve,
	reject,
	confirm,
	attachFile,
	cancel,
	cancelTypes,
	remove,
	insertItem,
	updateItem,
	deleteItem
};
