import RestApi from "@/api/RestApi";

export default class FilesApi extends RestApi {
	constructor() {
		super();
		this.baseEndpoint = this.domain + "files/";
	}

	async getList(pagination) {
		let url = this.baseEndpoint;
		let response = await this.getData(url, pagination);
		return response;
	}

	async getItem(id) {
		let url = this.baseEndpoint + id + '/edit';
		let response = await this.getData(url);
		return response;
	}

	async insertItem(item) {
		let url = this.baseEndpoint;
		let response = await this.insertData(url, item);
		return response;
	}

	async updateItem(id, item) {
		let url = this.baseEndpoint + id;
		let response = await this.putData(url, item);
		return response;
	}

	async uploadFile(data) {
		let url = this.baseEndpoint + 'upload';
		let response = await this.insertData(url, data);
		return response;
	}
	async getFileName(data) {
		let url = this.domain + 'get-file/?file_path=' + data;
		let response = await this.getData(url);
		return response;
	}

	async deleteItem(id) {
		let url = this.baseEndpoint + id;
		let response = await this.deleteData(url);
		return response;
	}
}
