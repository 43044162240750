const items = state => state.items;
const total = state => state.total;
const item = state => state.item;
const permissions = state => state.permissions;
const loading = state => state.loading;
const pagination = state => state.pagination;

export default {
	items,
	total,
	item,
	loading,
	pagination
};
