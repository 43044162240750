<template>
  <loader></loader>
</template>
<script>
export default {
  components: {
    Loader: () => import("@/components/core/Loader")
  },
  data() {
    return {};
  },
  mounted() {
    this.logout();
  },
  methods: {
    logout() {
      this.$auth
        .logout({
          redirect: { name: "login" }
        })
        .then(() => {
          this.$auth.unremember();
        });
    }
  }
};
</script>
