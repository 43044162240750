<template>
  <form-wizard
    :form-data="formData"
    :item="item"
    :namespace="namespace"
    :title="title"
    :icon="icon"
    :store="store"
    :store-action="storeAction"
  ></form-wizard>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "CompanyTypeCreate",
  components: {
    FormWizard: () => import("@/components/core/form/FormWizard")
  },
  data() {
    return {
      item: {}
    };
  },
  created() {},
  mounted() {},
  computed: {
    icon() {
      return this.$route.meta.icon || "";
    },
    storeAction() {
      return this.$route.meta.storeAction || "";
    },
    store() {
      return this.$route.meta.store || "";
    },
    title() {
      return this.$route.meta.title || "";
    },
    namespace() {
      return this.$route.meta.namespace || "";
    },
    formData() {
      let form = [
        {
          text: "Company Type Name*",
          value: "name",
          flex: "col-sm-12 col-md-12",
          required: "required",
          class: "",
          validate: "required"
        },
        {
          text: "Description*",
          value: "description",
          flex: "col-sm-12 col-md-12",
          textarea: true,
          required: "required",
          class: "",
          validate: "required"
        }
      ];
      return form;
    }
  }
};
</script>
