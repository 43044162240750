import RestApi from "@/api/RestApi";

export default class AccessLogsApi extends RestApi {
	constructor() {
		super();
		this.baseEndpoint = this.domain + "access-log/";
	}

	async getList(pagination) {
		let url = this.baseEndpoint;
		let response = await this.getData(url, pagination);
		return response;
	}

	async showItem(id) {
		let url = this.baseEndpoint + id;
		let response = await this.getData(url);
		return response;
	}
}
