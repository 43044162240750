import ProductivityApi from "@/api/productivity";
import ErrorHandling from "@/plugins/ErrorHandling";
const errorHandling = new ErrorHandling();
const restApi = new ProductivityApi();

const getItems = async ({ commit, state }, all = false) => {
	try {
		commit("setLoading", true);
		const options = JSON.parse(JSON.stringify(state.pagination));
		Object.keys(options).forEach(function() {
			options["perPage"] = options["rowsPerPage"];
		});
		const items = await restApi.getList(all ? all : options);
		commit("setItemsData", items);
		commit("setLoading", false);
	} catch (error) {
		errorHandling.onError(error);
	}
};

const setPagination = async ({ commit }, payload) => {
	commit("setPagination", payload);
};

const getActivityLog = async ({ commit }, itemId) => {
	commit("setLoading", true);
	try {
		const item = await restApi.getActivityLog(itemId);
		commit("setActivityLog", item);
		commit("setLoading", false);
	} catch (error) {
		errorHandling.onError(error, { itemId });
	}
};

const getItem = async ({ commit }, itemId) => {
	try {
		commit("setLoading", true);
		const item = await restApi.getItem(itemId);
		commit("setItemData", item);
		commit("setLoading", false);
	} catch (error) {
		errorHandling.onError(error);
	}
};

const generateProductivity = async ({ commit }, item) => {
	try {
		commit("setLoading", true);
		await restApi.generateProductivity(item);
		commit("setLoading", false);
	} catch (error) {
		errorHandling.onError(error);
	}
};

const uploadProductivity = async ({ commit }, item) => {
	try {
		commit("setLoading", true);
		await restApi.uploadProductivity(item);
		commit("setLoading", false);
	} catch (error) {
		errorHandling.onError(error);
	}
};

export default {
	getItems,
	getItem,
	setPagination,
	generateProductivity,
	uploadProductivity
};
