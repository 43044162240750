import CompaniesApi from "@/api/companies";
import ErrorHandling from "@/plugins/ErrorHandling";
import _ from "lodash";
const restApi = new CompaniesApi();
const errorHandling = new ErrorHandling();

const getItems = async ({ commit, state }, all = false) => {
	try {
		commit("setLoading", true);
		commit("setEmptyItems");
		const options = JSON.parse(JSON.stringify(state.pagination));
		Object.keys(options).forEach(function() {
			options["perPage"] = options["rowsPerPage"];
			options["orderDir"] = options["sortDesc"] ? "desc" : "asc";
		});
		const items = await restApi.getList(all ? all : options);
		commit("setItemsData", items);
		commit("setLoading", false);
	} catch (error) {
		errorHandling.onError(error);
	}
};

const getSuppliers = async ({ commit, state }, payload) => {
	try {
		commit("setLoading", true);
		const items = await restApi.getList({
			all: true,
			company_group: payload.company_group
		});
		commit("setSuppliersData", { name: payload.name, data: items });
		commit("setLoading", false);
	} catch (error) {
		errorHandling.onError(error);
	}
};

const getKPI = async ({ commit, state }, id) => {
	try {
		commit("setLoading", true);
		const items = await restApi.getKPI(id);
		commit("setKPI", items);
		commit("setLoading", false);
	} catch (error) {
		errorHandling.onError(error);
	}
};

const assignCompany = async ({ commit, dispatch }, item) => {
	try {
		commit("setLoading", true);
		item.companies = item.companies.map(function(value, index, array) {
			if (value.id) {
				return value.id;
			}
			return value;
		});
		await restApi.assignCompany(item);
		dispatch("getItems");
		commit("setLoading", false);
	} catch (error) {
		errorHandling.onError(error);
	}
};

const setPagination = async ({ commit }, payload) => {
	commit("setPagination", payload);
};

const getActivityLog = async ({ commit }, itemId) => {
	commit("setLoading", true);
	try {
		const item = await restApi.getActivityLog(itemId);
		commit("setActivityLog", item);
		commit("setLoading", false);
	} catch (error) {
		errorHandling.onError(error, { itemId });
	}
};

const getItem = async ({ commit }, itemId) => {
	try {
		commit("setLoading", true);
		const item = await restApi.getItem(itemId);
		commit("setItemData", item);
		commit("setLoading", false);
	} catch (error) {
		errorHandling.onError(error);
	}
};

const showItem = async ({ commit }, itemId) => {
	try {
		commit("setLoading", true);
		const item = await restApi.showItem(itemId);
		commit("setItemData", item);
		commit("setLoading", false);
	} catch (error) {
		errorHandling.onError(error);
	}
};

const insertItem = async ({ commit }, item) => {
	try {
		commit("setLoading", true);
		await restApi.insertItem(item);
		commit("setLoading", false);
	} catch (error) {
		errorHandling.onError(error);
	}
};

const updateItem = async ({ commit }, item) => {
	try {
		commit("setLoading", true);

		if (_.isEmpty(item.logo)) {
			item.logo = null;
		}
		await restApi.updateItem(item.id, item);
		commit("setLoading", false);
	} catch (error) {
		errorHandling.onError(error);
	}
};

const deleteItem = async ({ commit }, item) => {
	try {
		commit("setLoading", true);
		await restApi.deleteItem(item);
		commit("setLoading", false);
	} catch (error) {
		errorHandling.onError(error);
	}
};

export default {
	getItems,
	getItem,
	showItem,
	getKPI,
	assignCompany,
	getActivityLog,
	getSuppliers,
	setPagination,
	insertItem,
	updateItem,
	deleteItem
};
