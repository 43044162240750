<template>
  <v-container></v-container>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "PermissionShow",
  data() {
    return {};
  },
  created() {},
  computed: {}
};
</script>
