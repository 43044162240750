import View from "@/views/City.vue";
import Index from "@/components/cities/List.vue";
import Create from "@/components/cities/Create.vue";
import Edit from "@/components/cities/Edit.vue";
import Show from "@/components/cities/Show.vue";
import store from "@/store";

export default [
	{
		path: "/city",
		name: "city",
		component: View,
		title: "City",
		redirect: { name: "list-city" },
		children: [
			{
				path: "list",
				name: "list-city",
				meta: {
					icon: "mdi-city"
				},
				beforeEnter: (to, from, next) => {
					store.commit("options/setOptionsData", {
						dialog: false
					});
					next();
				},
				components: {
					page: Index
				}
			},
			{
				path: "create",
				name: "create-city",
				meta: {
					storeAction: "cities/insertItem",
					namespace: "city",
					store: "cities",
					title: "Create city",
					icon: "mdi-city"
				},
				beforeEnter: (to, from, next) => {
					store.commit("options/setOptionsData", {
						dialog: true
					});
					next();
				},
				components: {
					modal: Create
				}
			},
			{
				path: "edit/:id",
				name: "edit-city",
				meta: {
					storeAction: "cities/updateItem",
					namespace: "city",
					store: "cities",
					title: "Edit city",
					icon: "mdi-city"
				},
				beforeEnter: (to, from, next) => {
					store.commit("options/setOptionsData", {
						dialog: true
					});
					next();
				},
				components: {
					modal: Edit
				}
			},
			{
				path: "show/:id",
				name: "show-city",
				components: {
					page: Show
				}
			}
		]
	}
];
