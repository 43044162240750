<template>
  <v-container
    ><v-subheader>
      <div class="headline grey--text text--darken-3">
        <v-icon color="primary" left>mdi-cog</v-icon>
        Comming soon
      </div>
    </v-subheader></v-container
  >
</template>
<script>
export default {
  name: "productivityShow",
  data() {
    return {};
  },
  created() {},
  computed: {}
};
</script>
