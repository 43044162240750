<template>
  <v-container fluid>
    <v-row class="px-10">
      <v-col cols="12">
        <div class="primary--text display-1  font-weight-bold  py-3">
          MASTER VENDOR SOLUTION
        </div>
      </v-col>
      <v-col cols="12" md="12"> <v-divider></v-divider></v-col>
    </v-row>
    <v-row class="px-10">
      <template v-for="i in 5">
        <v-col cols="12" :key="'A' + i">
          <v-img :src="require('@/assets/mvs/page-' + i + '.jpg')"></v-img>
        </v-col>
        <v-col cols="12" :key="'B' + i"> <v-divider></v-divider></v-col>
      </template>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "MasterVendorSolution",
  data() {
    return {};
  },
  components: {},
  computed: {}
};
</script>
