<template>
  <v-container v-if="dataList" class="pa-8" fluid>
    <v-row no-gutters>
      <v-col cols="12" md="6" align="start">
        <h2>
          <v-icon :color="color" large left>{{ icon }}</v-icon>
          {{ currentRoute.title }}
        </h2>
      </v-col>
      <v-col cols="12" md="6" align="end">
         <span class="caption grey--text">
                        <span class="font-weight-bold"> Showing {{
                            (undefined !== dataList) ? dataList.length : 0
                          }} </span>/ <span
             class="text--darken-3">{{ dataList.length }}</span>
                    </span>
      </v-col>
    </v-row>
    <v-data-iterator :items="dataList" :options.sync="pagination" :server-items-length="dataList.length"
                     hide-default-footer>
      <template v-slot:item="props">
        <v-hover v-slot="{ hover }" open-delay="100">
          <v-card
              :to="{name:'show-' +[routeName], params: {id:props.item.id}}"
              class="dekra-card px-4 py-2 mt-3 text-left" :class="{ 'on-hover': hover }"
              v-bind:style="'--dekra-border-color:'+colorHex">
            <v-card-title>
              <v-row class="align-content-center">
                <v-col cols="1" v-if="props.item.logo_preview">
                  <v-avatar size="70" tile class="pr-2">
                    <v-img contain :src="props.item.logo_preview"></v-img>
                  </v-avatar>
                </v-col>
                <v-col
                    :cols="props.item.logo_preview ? 6 : 8"
                    class="text-truncate"
                >
                  <span class="font-weight-light dekra-grey-lighten-2 caption mb-2 layout"
                        v-if="routeName!=='workforce'">
                  {{ currentRoute.title }} ID {{ props.item.id }}
                </span>
                  <span class=" dekra-grey-lighten-2 body-2 mb-1 layout"
                        v-if="routeName==='workforce'">
                  {{ props.item.company["name"] }}
                </span>
                  <span>{{ props.item.name }}</span>
                  <span class="font-weight-light dekra-grey-lighten-2 caption layout"
                        v-if="routeName==='workforce'">
                  {{ props.item.workforce_request_type["display_name"] }}
                </span>
                  <div v-if="props.item.on_job_position">
                    <span
                        class="subtitle-1 grey--text text--darken-1  d-flex">{{
                        props.item.on_job_position.name
                      }}, {{ props.item.on_workforce_request.name }} @
                      <strong class="ml-1"> {{
                          props.item.on_workforce_request.company.name
                        }}</strong></span>
                    <div class="caption grey--text body-2">Started At:
                      {{ formatDate(props.item.on_job_position["started_at"]) }}
                    </div>
                  </div>
                </v-col>
                <v-col cols="3" class="align-content-center caption"
                       v-if="routeName === 'employee' || routeName ==='candidate'">
                  <v-avatar size="70" tile class="pr-2">
                    <v-img contain :src="props.item.company.logo_preview"></v-img>
                  </v-avatar>
                  {{ props.item.company["name"] }}
                </v-col>
                <v-col cols="1" align-self="center" v-if="props.item.fill_rate">
                  <div
                      class="caption grey--text body-2 justify-end">Fill rate:
                  </div>
                </v-col>
                <v-col cols="3" v-if="props.item.fill_rate" align-self="center">
                  <v-row>
                    <v-col cols="7" align-self="center">
                      <v-progress-linear
                          color="green"
                          height="7"
                          :value="valueForProgress(props.item.fill_rate.fill_rate)"
                      >
                      </v-progress-linear>
                    </v-col>
                    <v-col cols="3">
                      <span
                          class="body-1 my-1 text--darken-3">{{
                          valueForProgress(props.item.fill_rate.fill_rate)
                        }}%</span>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-title>
            <v-divider></v-divider>
            <v-row class="my-2 " justify="space-around">
              <v-col class="caption mx-3" cols="3" align-self="center" v-if="props.item.city">
                <span class=" grey--text">City: </span> {{ props.item.city["name"] }}
              </v-col>
              <v-col class="caption mx-3" cols="3" v-if="props.item.company_status">
                <span class="grey--text"> Company status : </span>
                {{ props.item.company_status["name"] }}
              </v-col>
              <v-col class="caption mx-3" cols="3" v-if="props.item.company_type">
                <span class="grey--text"> Company type : </span>
                {{ props.item.company_type["name"] }}
              </v-col>
              <v-col class="caption mx-3" cols="3" v-if="props.item.email">
                <span class="caption grey--text">Email: </span>
                {{ props.item["email"] }}
              </v-col>
              <v-col class="caption mx-3" cols="3" v-if="props.item.phone_number">
                <span class="caption grey--text">Phone Number: </span>
                {{ props.item["phone_number"] }}
              </v-col>
              <v-col class="caption mx-3" v-if="props.item.description">
                <span class="caption grey--text">Description: </span> {{ props.item["description"] }}
              </v-col>

            </v-row>
            <v-row
                align="center"
                justify="end" v-if="props.item['created_at']"
            >
              <span class="caption grey--text ma-3">Created at: {{ formatDate(props.item["created_at"]) }}</span></v-row>
          </v-card>
        </v-hover>
      </template>
    </v-data-iterator>
  </v-container>
</template>
<script type="text/javascript">
import _ from 'lodash'
import date from '@/mixins/date'
import file from '@/mixins/file'
import permission from '@/mixins/permission'

export default {
  mixins: [permission, date, file],

  data() {
    return {
      percentage: '',
      routeParams: {}
    }
  },
  props: {
    namespace: {
      type: String
    },
    dataList: {
      type: Array,
    },
    routeName: {
      type: String,
      required: true
    },
    currentRoute: {
      type: Object,
      required: false
    }
  },
  watch: {
    pagination: {
      handler() {
        let _this = this
      },
      deep: true
    }
  },
  async mounted() {
    let pagination = this.$store.getters[this.namespace + '/pagination']
    await this.$store.dispatch(this.namespace + '/setPagination', pagination)
  },
  computed: {
    items: {
      get() {
        return this.$store.getters[this.namespace + '/items']
      },
      set(value) {
        this.$store.dispatch(this.namespace + '/getItems', value)
      }
    },
    pagination: {
      get() {
        return this.$store.getters[this.namespace + '/pagination']
      },
      set(value) {
        this.$store.dispatch(this.namespace + '/setPagination', value)
      }
    },
    icon() {
      return this.currentRoute.meta.icon
    },
    textColor() {
      return this.currentRoute.meta.textColor || "primary--text";
    },
    color() {
      return this.currentRoute.meta.color || "primary";
    },
    colorHex() {
      return this.currentRoute.meta.colorHex || "#017d40";
    }
  },
  methods: {
    valueForProgress(fill_rate) {
      return fill_rate * 100
    },
  },
};

</script>
<style scoped>
.dekra-card {
  cursor: pointer;
  transition: transform 0.2s;
  background-color: #f8f8f8 !important;

}

.dekra-card.on-hover {
  border-left: 4px solid var(--dekra-border-color);
  transform: scale(1.02);
}
</style>
