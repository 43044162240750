<template>
  <form-wizard
    v-if="item"
    :form-data="formData"
    :item="item"
    :namespace="namespace"
    :title="title"
    :icon="icon"
    :store="store"
    :store-action="storeAction"
  ></form-wizard>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "CompanyEdit",
  components: {
    FormWizard: () => import("@/components/core/form/FormWizard")
  },
  data() {
    return {
      item: {}
    };
  },
  async mounted() {
    const _this = this;
    await _this.$store
      .dispatch(_this.store + "/getItem", _this.$route.params.id)
      .then(() => {
        _this.item = _this.$store.getters[_this.store + "/item"];
        _this.item.preview_url = _this.item.logo_preview;
      });
    await this.$store.dispatch("cities/getItems", { all: true });
    await this.$store.dispatch("companyTypes/getItems", { all: true });
  },
  computed: {
    ...mapGetters({
      cities: "cities/items",
      companyTypes: "companyTypes/items"
    }),
    icon() {
      return this.$route.meta.icon || "";
    },
    storeAction() {
      return this.$route.meta.storeAction || "";
    },
    store() {
      return this.$route.meta.store || "";
    },
    title() {
      return this.$route.meta.title || "";
    },
    namespace() {
      return this.$route.meta.namespace || "";
    },
    formData() {
      let form = [
        {
          text: "Company Name*",
          value: "name",
          flex: "col-sm-12 col-md-12",
          required: "required",
          class: "",
          validate: "required"
        },
        {
          text: "Logo*",
          value: "logo",
          flex: "col-sm-12 col-md-12",
          required: "required",
          fileUpload: true,
          preview_url: "",
          class: "",
          validate: ""
        },
        {
          text: "City*",
          value: "city_id",
          options: this.cities,
          flex: "col-sm-12 col-md-6",
          required: "required",
          class: "",
          validate: "required"
        },
        {
          text: "Company type*",
          value: "company_type_id",
          options: this.companyTypes,
          flex: "col-sm-12 col-md-6",
          required: "required",
          class: "",
          validate: "required"
        },
        {
          text: "Description*",
          value: "description",
          flex: "col-sm-12 col-md-12",
          required: "required",
          textarea: true,
          class: "",
          validate: "required"
        }
      ];
      return form;
    }
  }
};
</script>
