import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import { Ripple } from 'vuetify/lib/directives'

Vue.use(Vuetify, {
	directives: {
		Ripple
	}
});

export default new Vuetify({
	theme: {
		dark: !!JSON.parse(localStorage.getItem("theme")),
		themes: {
			light: {
				primary: "#017d40",
				secondary: "#ff9800",
				accent: "#fcfcfc",
				dirtyWhite: "#fefefe",
				error: "#f44336",
				warning: "#ff5722",
				info: "#03a9f4",
				success: "#017d40"
			},
			dark: {
				primary: "#017d40",
				secondary: "#ff9800",
				dirtyWhite: "#fefefe",
				accent: "#fafafa",
				error: "#f44336",
				warning: "#ff5722",
				info: "#03a9f4",
				success: "#017d40"
			}
		},
		icons: {
			iconfont: "mdi" // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
		}
	}
});
