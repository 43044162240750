import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
	items: [],
	total: 0,
	newNotifications: [],
	newNotificationsBadge: 0,
	loading: false,
	loadingNew: false,
	pagination: {
		page: 1,
		search: "",
		perPage: 10,
		loadRows: 10
	}
};

export default {
	namespaced: true,
	state,
	actions,
	getters,
	mutations
};
