import View from "@/views/AccessLog.vue";
import Index from "@/components/accessLogs/List.vue";
import Show from "@/components/accessLogs/Show.vue";
import store from "@/store";

export default [
	{
		path: "/access-logs",
		name: "accessLogs",
		component: View,
		redirect: {
			name: "list-accessLog"
		},
		children: [
			{
				path: "list",
				name: "list-accessLog",
				meta: {
					icon: "mdi-wifi-alert"
				},
				beforeEnter: (to, from, next) => {
					store.commit("options/setOptionsData", {
						dialog: false
					});
					next();
				},
				components: {
					page: Index
				}
			},
			{
				meta: {
					namespace: "accessLog",
					title: "Access Logs",
					store: "accessLogs",
					icon: "mdi-wifi-alert"
				},
				beforeEnter: (to, from, next) => {
					store.commit("options/setOptionsData", {
						dialog: false
					});
					next();
				},
				path: "show/:id",
				name: "show-accesLog",
				components: {
					page: Show
				}
			}
		]
	}
];
