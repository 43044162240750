<template>
  <v-container fluid>
    <v-row justify="space-around" no-gutters>
      <v-col cols="12">
        <v-sheet color="grey lighten-5" class="pa-12" v-if="item">
          <v-row align="start" justify="start">
            <v-col cols="8" class="text-left">
              <h1 class="grey--text text--darken-2 mb-2 display-2">
                <v-avatar size="100" v-if="item.company">
                  <v-img
                      contain
                      alt="company"
                      :src="item.company.logo_preview"
                  />
                </v-avatar>
                <span v-if="item.company">{{ item.company.name }}</span>
              </h1>
            </v-col>
            <v-col>
              <div
                  class="pa-6 headline text--darken-2"
                  v-if="item.fill_rate"
              >
                <span>Fill rate</span><br/>
                {{ item.fill_rate.fill_rate_percentage }} ({{
                  item.fill_rate.totalFilled
                }}/{{ item.fill_rate.positions_to_fill }})
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="text-left">
              <h2
                  class="text-uppercase grey--text text--darken-1 ma-2 display-1"
              >
                <span v-if="item.name">{{ item.name }}</span>
              </h2>
              <div
                  class="ma-2 mb-4 headline font-weight-light grey--text text--darken-2"
                  v-if="item.workforce_request_type"
              >
                {{ item.workforce_request_type.display_name }}
                <span class="title body-2 grey--text text--darken-2"
                >( start from {{ formatDate(item.created_at) }} )</span
                >
              </div>
              <div v-if="item.description" class="font-italic">
                {{ !readAll ? item.description.slice(0, 270) : item.description }}
                <span v-if="item.description.length>270">
                  <v-btn plain text class="pl-0 grey--text" @click="readAll=true" retain-focus-on-click v-show="!readAll">... Read more</v-btn>
                  <v-btn plain text class="pl-0 grey--text" @click="readAll=false" retain-focus-on-click v-show="readAll">Read less</v-btn>
                </span>
              </div>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
      <v-col cols="12">
        <v-tabs
            v-model="tab"
            :background-color="color"
            :active-class="'grey lighten-5 ' + textColor"
            grow
            dark
            icons-and-text
        >
          <v-tabs-slider color="transparent"></v-tabs-slider>

          <v-tab href="#tab-1" v-if="userCan('assign-employee')">
            Assign
            <v-icon>mdi-account-group</v-icon>
          </v-tab>
          <v-tab href="#tab-1" v-else>
            Employees
            <v-icon>mdi-account-hard-hat</v-icon>
          </v-tab>

          <v-tab href="#tab-2" v-if="userCan('list-company')">
            HR suppliers
            <v-icon>mdi-swap-vertical-bold</v-icon>
          </v-tab>

          <v-tab href="#tab-3" v-if="userCan('list-company')">
            Files
            <v-icon>mdi-file-multiple</v-icon>
          </v-tab>
          <v-tab href="#tab-4" v-if="userCan('read-comments-workforce')">
            Comments
            <v-badge
                :content="item.comments_count"
                :value="item.comments_count"
                overlap
            >
              <v-icon>mdi-comment-text-multiple</v-icon>
            </v-badge>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item :value="'tab-1'" v-if="userCan('assign-employee')">
            <assign-tab></assign-tab>
          </v-tab-item>
          <v-tab-item :value="'tab-1'" v-else>
            <employees-list :show-data-only="true"></employees-list>
          </v-tab-item>
          <v-tab-item :value="'tab-2'" v-if="userCan('list-company')">
            <company-list
                v-if="item.companies"
                :show-data-only="true"
            ></company-list>
          </v-tab-item>
          <v-tab-item :value="'tab-3'" v-if="userCan('list-file')">
            <file-list
                v-if="item.files"
                :show-data-only="true"
                :passed-data="item.files"
            ></file-list>
          </v-tab-item>
          <v-tab-item
              :value="'tab-4'"
              v-if="userCan('read-comments-workforce')"
          >
            <comment-list type="workforce-requests" :typeId="id"></comment-list>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import permission from "@/mixins/permission";
import date from "@/mixins/date";

export default {
  Name: "workforceRequestShow",
  components: {
    FileList: () => import("@/components/files/List"),
    CompanyList: () => import("@/components/companies/List"),
    AssignTab: () => import("@/components/workforceRequests/tabs/AssignTab"),
    EmployeesList: () => import("@/components/employees/List"),
    CommentList: () => import("@/components/comments/List")
  },
  mixins: [permission, date],
  data() {
    return {
      item: {},
      tab: null,
      readAll: false
    };
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch("companies/setPagination", {
      workforce_request_id: null,
      company_group: null
    });
    this.$store.dispatch("files/setPagination", {workforce_request_id: null});
    this.$store.dispatch("employees/setPagination", {
      company_id: null
    });
    next();
  },
  async mounted() {
    const _this = this;
    this.$store.dispatch("companies/setPagination", {
      workforce_request_id: _this.id,
      company_group: "hr-suppliers"
    });
    this.$store.dispatch("files/setPagination", {
      workforce_request_id: _this.id
    });

    _this.$store.dispatch(_this.store + "/showItem", _this.id).then(() => {
      _this.item = _this.$store.getters[_this.store + "/item"];
      this.$store.dispatch("employees/setPagination", {
        company_id: _this.item.company.id
      });
    });
  },
  computed: {
    icon() {
      return this.$route.meta.icon || "";
    },
    id() {
      return this.$route.params.id || null;
    },
    textColor() {
      return this.$route.meta.textColor || "";
    },
    color() {
      return this.$route.meta.color || "";
    },
    store() {
      return this.$route.meta.store || "";
    },
    title() {
      return this.$route.meta.title || "";
    },
    namespace() {
      return this.$route.meta.namespace || "";
    }
  },
  methods: {}
};
</script>
