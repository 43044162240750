import Vue from "vue";

Vue.use(require("vue-pusher"), {
	api_key: process.env.VUE_APP_PUSHER_API_KEY,
	options: {
		cluster: "eu",
		app_id: process.env.VUE_APP_PUSHER_APP_ID,
		app_secret: process.env.VUE_APP_PUSHER_APP_SECRET
		//	encrypted: true
	}
});
