<template> <div></div> </template>
<script>
export default {
  name: "AccessLogsShow",
  components: {},
  data() {
    return {
      item: {}
    };
  },
  created() {},
  async mounted() {},
  computed: {}
};
</script>
