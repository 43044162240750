<template>
  <report-list
    :icon="icon"
    :title="title"
    :route-name="routeName"
    :namespace="namespace"
    :names="names"
  ></report-list>
</template>
<script>
export default {
  name: "ReportsList",
  components: {
    ReportList: () => import("@/components/core/lists/ReportList")
  },
  data() {
    return {
      routeName: "report",
      namespace: "reports",
      title: "Report",
      names: [
        {
          text: "#",
          value: "id",
          sortable: false
        },
        {
          text: "Name",
          value: "name",
          sortable: false
        },
        {
          text: "Status",
          value: "status",
          sortable: false
        },
        {
          text: "Created at",
          value: "created_at",
          sortable: false
        },
        {
          text: "Actions",
          value: "actions",
          align: "center",
          sortable: false
        }
      ]
    };
  },
  computed: {
    icon() {
      return this.$route.meta.icon || "";
    }
  },
  created() {}
};
</script>
