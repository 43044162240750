<template>
  <v-row no-gutters>
    <v-col sm="12" md="8" class="fill-height hidden-sm-and-down align justify">
      <v-img :src="require('@/assets/login-image.png')" contain> </v-img>
    </v-col>
    <v-col sm="12" md="4">
      <ValidationObserver ref="observer" v-slot="{ invalid }">
        <v-form
          @keyup.native.enter="submitForm"
          id="loginForm"
          class="fill-height pa-10"
        >
          <v-container fluid class="fill-height">
            <v-row no-gutters>
              <v-col cols="12">
                <v-img
                  max-width="300"
                  :src="require('@/assets/dekra-arbeit-gruppe-logo.png')"
                  alt="Logo"
                ></v-img>
              </v-col>
              <v-col cols="12">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <v-text-field
                    prepend-icon="mdi-account"
                    v-model="email"
                    :error-messages="errors"
                    required
                    name="Email"
                    label="Your Email"
                    type="text"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="px-2">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <v-text-field
                    id="password"
                    :error-messages="errors"
                    prepend-icon="mdi-lock"
                    name="Password"
                    required
                    label="Please, enter your Password"
                    v-model="password"
                    :type="showPassword ? 'text' : 'password'"
                    @click:append="showPassword = !showPassword"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="text-right justify align ">
                <v-btn
                  tile
                  block
                  color="primary"
                  :disabled="invalid"
                  :loading="loading"
                  @click="submitForm"
                  class="text-transform"
                  >Login
                  <v-icon small right>mdi-exit-to-app</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </ValidationObserver>
    </v-col>
  </v-row>
</template>
<script>
import { required, email } from "vee-validate/dist/rules";
import { mapGetters } from "vuex";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode
} from "vee-validate";
setInteractionMode("eager");
extend("required", {
  ...required,
  message: "{_field_} can not be empty"
});

extend("email", {
  ...email,
  message: "Email must be valid"
});
export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      email: null,
      showPassword: false,
      password: null,
      text: ""
    };
  },
  computed: {
    ...mapGetters({
      loading: "options/loading"
    })
  },
  methods: {
    async submitForm() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        const credentials = {
          email: this.email,
          password: this.password
        };
        this.$auth
          .login({
            data: credentials,
            rememberMe: true,
            redirect: true,
            staySignedIn: true
          })
          .then(response => {
            this.$auth.user(JSON.parse(JSON.stringify(response.data.user)));
            this.$auth.remember(JSON.stringify(response.data.user));
            this.$auth.token("token", response.data.accessToken);
            window.axios.defaults.headers.common["Authorization"] =
              "Bearer " + response.data.accessToken;
            window.axios.defaults.headers.common["X-CSRF-TOKEN"] =
              response.data.csrf_token;
            this.$router.push({ name: "dashboard" });
          });
      }
    }
  }
};
</script>
<style scoped></style>
