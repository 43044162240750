export default {
	methods: {
		/**
		 * @param {string} permission
		 * Check if user have permission for some action
		 */
		userCan(permission) {
			if (this.$auth.check(permission)) {
				return true;
			}
			return false;
		},
		/**
		 * @param {string} role
		 * Check if user has specific role
		 */
		hasRole(role) {
			if (this.$auth.check()) {
				if (this.$auth.check({ name: role }, "roles")) {
					return true;
				}
			}
			return false;
		},
		/**
		 * @param {string} role
		 * Check if user has specific role group
		 */
		hasRoleGroup(role) {
			if (this.$auth.check()) {
				if (this.$auth.check({ group: role }, "roles")) {
					return true;
				}
			}
			return false;
		}
	}
};
