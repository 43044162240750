import Vue from "vue";
import Vuex from "vuex";
import options from "./options/index";
import users from "./users/index";
import cities from "./cities/index";
import countries from "./countries/index";
import dashboard from "./dashboard/index";
import permissions from "./permissions/index";
import comments from "./comments/index";
import reports from "./reports/index";
import productivity from "./productivity/index";
import companyTypes from "./companyTypes/index";
import companyStatus from "./companyStatus/index";
import workforceTypes from "./workforceTypes/index";
import workforce from "./workforceRequests/index";
import jobPositions from "./jobPositions/index";
import fileTypes from "./fileTypes/index";
import companies from "./companies/index";
import roles from "./roles/index";
import employees from "./employees/index";
import candidates from "./candidates/index";
import notifications from "./notifications/index";
import files from "./files/index";
import logs from "./logs/index";
import accessLogs from "./accessLogs/index";

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		options,
		users,
		countries,
		permissions,
		notifications,
		dashboard,
		jobPositions,
		roles,
		files,
		comments,
		logs,
		companies,
		candidates,
		employees,
		productivity,
		reports,
		companyTypes,
		companyStatus,
		accessLogs,
		workforce,
		workforceTypes,
		fileTypes,
		cities
	}
});
