<template>
  <simple-list
    :names="names"
    :icon="icon"
    :title="title"
    :route-name="routeName"
    :key-title="keyTitle"
    :namespace="namespace"
  ></simple-list>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "CountryList",
  components: {
    SimpleList: () => import("@/components/core/lists/SimpleList")
  },
  data() {
    return {
      routeName: "country",
      namespace: "countries",
      title: "Countries",
      keyTitle: "name",
      names: [
        {
          text: "Name",
          value: "name",
          sortable: true
        },
        {
          text: "ISO2",
          value: "iso2",
          sortable: true
        }
      ]
    };
  },
  computed: {
    icon() {
      return this.$route.meta.icon || "";
    }
  },
  created() {}
};
</script>
