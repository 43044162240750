<template>
  <v-container fluid>
    <v-row justify="space-around" no-gutters>
      <v-col cols="12">
        <v-sheet color="grey lighten-5" class="pa-12" v-if="item">
          <v-row align="start" justify="end">
            <v-col cols="12" class=" text-left">
              <div class="d-flex">
                <v-avatar size="100">
                  <v-icon color="error" size="60" left>mdi-alert-circle</v-icon>
                </v-avatar>
                <div class="ma-2">
                  <span
                    class="grey--text text--darken-2 mb-2 display-1 d-flex text-capitalize"
                    v-if="item.level"
                  >
                    {{ item.level }} {{ item.id }}</span
                  >
                  <span
                    class="grey--text text--darken-1 mb-2 display-1 d-flex text-capitalize"
                    v-if="item.environment"
                  >
                    Environment: {{ item.environment }}</span
                  >
                  <span
                    class="subtitle-1 grey--text text--darken-1 ma-2  d-flex"
                    v-if="item.date"
                  >
                    {{ formatDate(item.date) }}</span
                  >
                </div>
              </div>
            </v-col>
            <v-divider class="ma-1"></v-divider>
            <v-col cols="12">
              <div class="title grey--text text--darken-1 ma-2 text-left ">
                {{ item.context.message }}
              </div>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import date from "@/mixins/date";
export default {
  name: "LogsShow",
  mixins: [date],
  components: {},
  data() {
    return {
      item: {}
    };
  },
  created() {},
  async mounted() {
    const _this = this;
    _this.$store.dispatch(_this.store + "/showItem", _this.id).then(() => {
      _this.item = _this.$store.getters[_this.store + "/item"];
    });
  },
  computed: {
    icon() {
      return this.$route.meta.icon || "";
    },
    id() {
      return this.$route.params.id || null;
    },
    store() {
      return this.$route.meta.store || "";
    },
    title() {
      return this.$route.meta.title || "";
    },
    namespace() {
      return this.$route.meta.namespace || "";
    }
  }
};
</script>
