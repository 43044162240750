import RestApi from "@/api/RestApi";

export default class ContactApi extends RestApi {
	constructor() {
		super();
		this.baseEndpoint = this.domain + "lp/contact/";
	}

	async signUp(data) {
		let url = this.baseEndpoint;
		let response = await this.insertData(url, data);
		return response;
	}
}
