import Vue from "vue";
const setItemsData = (state, value) => {
	state.items = value.data ? value.data : value;
	state.total = value.total ? value.total : state.total;
};

const setEmptyItems = state => {
	state.items = [];
};

const setItemData = (state, value) => {
	state.item = value.data ? value.data : state.item;
};

const setActivityLog = (state, value) => {
	state.activityLog = value.data ? value.data : state.activityLog;
};

const setKPI = (state, value) => {
	state.kpis = value.data ? value.data : state.item;
};

const setSuppliersData = (state, payload) => {
	state[payload.name] = payload.data;
};

const setLoading = (state, value) => {
	state.loading = value;
};

const setPagination = (state, value) => {
	state.total = 0;
	Vue.set(state, "pagination", Object.assign(state.pagination, value));
};

export default {
	setSuppliersData,
	setEmptyItems,
	setItemsData,
	setActivityLog,
	setItemData,
	setKPI,
	setPagination,
	setLoading
};
