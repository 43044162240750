import Vue from "vue";
import VueRouter from "vue-router";
import Login from "@/views/Login.vue";
import MasterVendorSolution from "@/views/MasterVendorSolution.vue";
import DekraArbeit from "@/views/DekraArbeit.vue";
import Signup from "@/views/SignUp.vue";
import Logout from "@/views/Logout.vue";
import Cities from "@/router/cities";
import Countries from "@/router/countries";
import Roles from "@/router/roles";
import Files from "@/router/files";
import Users from "@/router/users";
import Logs from "@/router/logs";
import AccessLogs from "@/router/accessLogs";
import Permissions from "@/router/permissions";
import Reports from "@/router/reports";
import Productivity from "@/router/productivity";
import CompanyTypes from "@/router/companyTypes";
import CompanyStatus from "@/router/companyStatus";
import WorkforceTypes from "@/router/workforceTypes";
import WorkforceRequests from "@/router/workforceRequests";
import JobPositions from "@/router/jobPositions";
import FileTypes from "@/router/fileTypes";
import Companies from "@/router/companies";
import Employees from "@/router/employees";
import Candidates from "@/router/candidates";
import GlobalSearch from "@/router/globalSearch";

Vue.use(VueRouter);

function loadView(view) {
	return () => import(`../views/${view}.vue`);
}

const routes = [
	{
		path: "",
		component: loadView("WrapperLanding"),
		children: [
			{
				path: "/",
				name: "login",
				meta: {
					auth: false
				},
				component: Login
			},
			{
				path: "/master-vendor-solution",
				name: "mvs",
				component: MasterVendorSolution
			},
			{
				path: "/dekra-arbeit",
				name: "arbeit",
				component: DekraArbeit
			},
			{
				path: "/sign-up",
				name: "signup",
				component: Signup
			}
		]
	},
	{
		path: "/logout",
		name: "logout",
		meta: {
			auth: true
		},
		component: Logout
	},
	{
		path: "/dashboard",
		meta: {
			auth: true
		},
		component: loadView("Wrapper"),
		children: [
			{
				path: "",
				name: "dashboard",
				meta: {
					icon: "mdi-view-dashboard-outline"
				},
				component: loadView("Dashboard")
			},
			{
				path: "/notifications",
				name: "notification",
				component: loadView("Notification")
			},
			...Cities,
			...Countries,
			...Roles,
			...Users,
			...Logs,
			...AccessLogs,
			...CompanyTypes,
			...CompanyStatus,
			...WorkforceTypes,
			...WorkforceRequests,
			...JobPositions,
			...FileTypes,
			...Files,
			...Companies,
			...Reports,
			...Productivity,
			...Employees,
			...Candidates,
			...Permissions,
			...GlobalSearch
		]
	},
	{
		path: "*",
		redirect: {
			name: "login"
		}
	}
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes
});

Vue.router = router;
window.router = router;
export default router;
