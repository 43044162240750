import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
	hrSuppliers: [],
	hrSuppliersTotal: 0,
	loading: false,
	hrSuppliersHeaders: [{
			sortable: true,
			text: "ID",
			value: "id"
		},
		{
			sortable: true,
			text: "Company",
			value: "name"
		},
		{
			sortable: false,
			text: "Location",
			value: "city.name",
			align: "right"
		},
		{
			sortable: false,
			text: "Workforce",
			value: "employees_count",
			align: "right"
		},
		{
			sortable: false,
			text: "Workforce Requests",
			value: "workforce_requests_assigned_count",
			align: "right"
		}],
	pagination: {
		page: 1,
		orderBy: "name",
		mustSort: true,
		descending: true,
		sortDesc: true,
		search: "",
		rowsPerPage: 12,
		loadRows: 12
	}
};

export default {
	namespaced: true,
	state,
	actions,
	getters,
	mutations
};
