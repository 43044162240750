import View from "@/views/Country.vue";
import Index from "@/components/countries/List.vue";
import Create from "@/components/countries/Create.vue";
import Edit from "@/components/countries/Edit.vue";
import Show from "@/components/cities/Show.vue";
import store from "@/store";

export default [
	{
		path: "/country",
		name: "country",
		component: View,
		title: "Country",
		redirect: {
			name: "list-country"
		},
		children: [
			{
				path: "list",
				name: "list-country",
				meta: {
					icon: "mdi-map"
				},
				beforeEnter: (to, from, next) => {
					store.commit("options/setOptionsData", {
						dialog: false
					});
					next();
				},
				components: {
					page: Index
				}
	},
			{
				path: "create",
				name: "create-country",
				meta: {
					storeAction: "countries/insertItem",
					namespace: "country",
					store: "countries",
					title: "Create Country",
					icon: "mdi-map"
				},
				beforeEnter: (to, from, next) => {
					store.commit("options/setOptionsData", {
						dialog: true
					});
					next();
				},
				components: {
					modal: Create
				}
	},
			{
				path: "edit/:id",
				name: "edit-country",
				meta: {
					storeAction: "countries/updateItem",
					namespace: "country",
					store: "countries",
					title: "Edit Country ",
					icon: "mdi-map"
				},
				beforeEnter: (to, from, next) => {
					store.commit("options/setOptionsData", {
						dialog: true
					});
					next();
				},
				components: {
					modal: Edit
				}
	},
			{
				path: "show/:id",
				name: "show-country",
				components: {
					page: Show
				}
	}]
}];
