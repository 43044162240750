import Vue from "vue";
const setItemsData = (state, value) => {
	state.items = value.data ? value.data : value;
	state.total = value.total ? value.total : state.total;
};

const setEmptyItems = state => {
	state.items = [];
};

const setActivityLog = (state, value) => {
	state.activityLog = value.data ? value.data : state.activityLog;
};

const setItemData = (state, value) => {
	state.item = value.data ? value.data : state.item;
};

const setCancelTypes = (state, value) => {
	state.cancelTypes = value ? value : state.cancelTypes;
};

const setLoading = (state, value) => {
	state.loading = value;
};

const setPagination = (state, value) => {
	state.total = 0;
	Vue.set(state, "pagination", Object.assign(state.pagination, value));
};

export default {
	setItemsData,
	setEmptyItems,
	setItemData,
	setCancelTypes,
	setActivityLog,
	setPagination,
	setLoading
};
